import { handleActions } from "redux-actions";
import cookie from "react-cookie";

const initialState = {
  // isLoggedIn: localStorage.getItem('token') ? true : false,
  // token: localStorage.getItem('token'),
  user_id: null,
  isLoggedIn: cookie.load("token") ? true : false,
  token: cookie.load("token"),
  errorMessage: ""
};

export default handleActions(
  {
    LOGOUT: () => {
      cookie.remove("token");
      return {
        isLoggedIn: false,
        token: "",
        errorMessage: ""
      };
    },

    LOGGED_IN_USER_GET_SUCCESS: (state, action) => {
      if (state.user_id === action.response.id) {
        return state;
      }
      return {
        user_id: action.response.id
      };
    },

    LOGIN_POST_SUCCESS: (state, { response, ...action }) => {
      if (response.error) {
        return {
          user_id: null,
          isLoggedIn: false,
          token: "",
          errorMessage: response.message
        };
      }

      return {
        user_id: response.id,
        isLoggedIn: true,
        token: response.token,
        errorMessage: ""
      };
    }
  },
  initialState
);
