import React from "react";
import PropTypes from "prop-types";
import { Dropdown } from "semantic-ui-react";

export default class RegionalManagerSelect extends React.Component {
  render() {
    const { regional_managers, onChange, logged_in_user_id } = this.props;
    return (
      <div className="quote-approval-needed">
        <div className="quote-select-user">
          {Boolean(regional_managers.length) && (
            <Dropdown
              // value={approval_user_id}
              placeholder="Select a Regional Manager"
              fluid
              selection
              item
              options={regional_managers
                // .filter(item => item.value !== logged_in_user_id) // filter out self
                .map(item => ({
                  ...item,
                  key: item.value,
                  text: item.fullName
                }))}
              className="regional-manager-dropdown"
              onChange={onChange}
            />
          )}
        </div>
      </div>
    );
  }
}

RegionalManagerSelect.propTypes = {
  logged_in_user_id: PropTypes.number,
  regional_managers: PropTypes.array,
  onChange: PropTypes.func
};

RegionalManagerSelect.defaultProps = {
  onChange() {},
  regional_managers: []
};
