import React from "react";
import { Link } from "react-router";
import { connect } from "react-redux";
import { Button } from "semantic-ui-react";

import { getDashboardData } from "../../actions/dashboard";

import QuotePipeline from "../../components/QuotePipeline";
import SupervisorPanel from "./SupervisorPanel";

import MyDashboard from "./MyDashboard";

import "./Dashboard.css";

const today = new Date();
const lastYearToday = today;

lastYearToday.setFullYear(today.getFullYear() - 1);

class Dashboard extends React.Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getDashboardData());
  }

  render() {
    const {
      approvedQuotes = [],
      deniedQuotes = [],
      completedQuotes = [],
      openQuotes = [],
      counts,
      is_supervisor
    } = this.props;

    return (
      <div>
        <div className="dashboard-header">
          <h1 className="header">Quotes Dashboard</h1>
          <Link to="/dashboard/quotes" className="jump-to-quotes">
            <Button color="blue">Go to Quotes</Button>
          </Link>
        </div>

        <MyDashboard
          approvedQuotes={approvedQuotes}
          deniedQuotes={deniedQuotes}
          completedQuotes={completedQuotes}
          openQuotes={openQuotes}
        />

        <div className="supervisor-reports">
          <section className="dashboard-section">
            {Boolean(is_supervisor) && (
              <div>
                <h3>Supervisor Reports</h3>
                <p>
                  Supervisor reports contain information about you as well as all the users you manage in your region.
                </p>
              </div>
            )}

            <div className="dashboard-charts-container">
              <div className="item report-item">
                <QuotePipeline counts={counts} />
              </div>
            </div>

            <SupervisorPanel counts={counts} />
          </section>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { dashboard, user } = state;
  return {
    counts: dashboard.counts,
    openQuotes: dashboard.openQuotes,
    approvedQuotes: dashboard.approvedQuotes,
    deniedQuotes: dashboard.deniedQuotes,
    completedQuotes: dashboard.completedQuotes,
    is_supervisor: user.is_supervisor
  };
}

export default connect(mapStateToProps)(Dashboard);
