export function copyToClipboard(text, el) {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(text);
  } else if (window.clipboardData) {
    // IE
    window.clipboardData.setData("text", text);
  } else {
    console.log("running in iOS");
    console.log("el", el);
    // other browsers, iOS, Mac OS
    const range = document.createRange();
    const selection = window.getSelection();
    range.selectNodeContents(el);
    selection.removeAllRanges();
    selection.addRange(range);
    el.setSelectionRange(0, 999999);

    document.execCommand("copy");
  }
}
