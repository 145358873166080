import { handleActions } from "redux-actions";
import { assign, union, indexOf } from "lodash";

const initialState = {
  quoteIds: [],
  isLoading: false
};

export default handleActions(
  {
    QUOTES_GET_REQUEST: (state, { response }) => {
      return {
        ...state,
        isLoading: true
      };
    },

    QUOTES_GET_SUCCESS: (state, { response }) => {
      return Object.assign(
        {},
        {
          // quoteIds: union(response.result.items, state.quoteIds),
          quoteIds: response.result.items,
          pagination: response.result.pagination,
          isLoading: false
        }
      );
    },

    QUOTEBYID_GET_SUCCESS: (state, { response }) => {
      return Object.assign(
        {},
        {
          quoteIds: union(state.quoteIds, [response.result])
        }
      );
    },

    QUOTE_DELETE_SUCCESS: (state, { response }) => {
      const index = indexOf(state.quoteIds, response.id);
      const newIds = index > -1 ? [...state.quoteIds.slice(0, index), ...state.quoteIds.slice(index + 1)] : state;
      return assign({}, state, {
        quoteIds: newIds
      });
    },
    GENERATE_PUBLIC_LINK: state => {
      return {
        ...state,
        isLoading: true
      };
    }
  },
  initialState
);
