import React, { Component } from "react";
import "./AdminTop.css";

import UniversalSearch from "../UniversalSearch";

export default class AdminTop extends Component {
  render() {
    return (
      <div className="admin-top-wrapper">
        <div className="logo">
          <a href="/dashboard/quotes">
            <img src="/logo-2023.png" alt="logo" width="200" />
          </a>
        </div>
        {/* <UniversalSearch /> */}
      </div>
    );
  }
}
