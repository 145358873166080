import { Schemas } from "../schemas";
import { denormalize } from "normalizr";

export function denormalizeQuote(state, quoteId) {
  return denormalize(quoteId, Schemas.QUOTE, state.entities);
}

export function denormalizeQuotes(state, quoteIds) {
  return denormalize(quoteIds, [Schemas.QUOTE], state.entities);
}

export function denormalizeUser(state, userId) {
  return denormalize(userId, Schemas.USER, state.entities);
}
