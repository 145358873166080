import { handleActions, combineActions } from "redux-actions";

const initialState = {
  isLoading: false
};

export default handleActions(
  {
    [combineActions(
      "ENABLE_LOADING",
      "QUOTES_GET_REQUEST",
      "GENERATE_PUBLIC_LINK_REQUEST",
      "QUOTEBYID_GET_REQUEST",
      "GENERATE_PUBLIC_LINK_POST_REQUEST"
    )]: state => {
      return {
        ...state,
        isLoading: true
      };
    },
    [combineActions(
      "DISABLE_LOADING",
      "QUOTES_GET_SUCCESS",
      "QUOTES_GET_FAILURE",
      "GENERATE_PUBLIC_LINK_SUCCESS",
      "GENERATE_PUBLIC_LINK_FAILURE",
      "QUOTEBYID_GET_SUCCESS",
      "QUOTEBYID_GET_FAILURE",
      "GENERATE_PUBLIC_LINK_POST_SUCCESS",
      "GENERATE_PUBLIC_LINK_POST_FAILURE"
    )]: state => {
      return {
        ...state,
        isLoading: false
      };
    }
  },
  initialState
);
