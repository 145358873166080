import React, { Component } from "react";
import { Link } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Button } from "semantic-ui-react";
import PurchaseOrderList from "./PurchaseOrderList";

import { getPurchaseOrders } from "../../actions/purchase_orders";

class PurchaseOrders extends Component {
  componentDidMount() {
    this.props.dispatch(getPurchaseOrders());
  }

  render() {
    return (
      <div className="section-wrapper">
        <h1>Purchase Orders</h1>
        <section className="section section--quotes">
          <div className="content-panel">
            <PurchaseOrderList />
          </div>
          <div className="action-panel">
            <div className="action-panel">
              {/* <Link to="/dashboard/quotes/create">
                <Button color="red" className="btn--create">Create a Purchase Order?</Button>
              </Link> */}
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default connect()(PurchaseOrders);
