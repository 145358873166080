import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { Link } from "react-router";
import { push } from "react-router-redux";
import get from "lodash/get";
import numeral from "numeral";
import { Button, Table, Modal, Header, Icon } from "semantic-ui-react";
import { deleteQuote, completeQuote } from "../../actions/quotes";
import { getOCLMUsers } from "../../actions/users";
import Pagination from "../../components/Pagination";
import { getApprovalStatus } from "../../utils/quotes";
import { formatQuoteNumber } from "../../utils/quotes";

import {
  APPROVED,
  DENIED,
  AWAITING_OCLM_APPROVAL,
  AWAITING_CLIENT_APPROVAL,
  QUOTE_COMPLETED,
  QUOTE_PRICE_NEEDS_APPROVAL,
} from "../../constants";
import "./Quotes.css";

function QuoteItem({ item, onDelete, onComplete, approver = {} }) {
  const status = getApprovalStatus(item.approval_id, item.approval_user_id, item.manager_approved_on);

  return (
    <Table.Row key={item.id}>
      <Table.Cell>{get(item, "portal_user.fullName")}</Table.Cell>
      <Table.Cell>{get(item, "manager.name")}</Table.Cell>
      <Table.Cell>
        <Link className="quote-edit" to={`/dashboard/quotes/view/${item.id}`}>
          {item.quote_no}
        </Link>
      </Table.Cell>
      <Table.Cell>
        <Link className="quote-edit" to={`/dashboard/quotes/view/${item.id}`}>
          {item.name}
        </Link>
        <p>{item.description}</p>
      </Table.Cell>
      <Table.Cell>
        <Link className="quote-edit" to={`/dashboard/quotes/view/${item.id}`}>
          {numeral(item.total).format("$0,0.00")}
        </Link>
      </Table.Cell>

      <Table.Cell className="quote-item-status">
        <div className={`${status.className} tag-status`}>{status.label}</div>
      </Table.Cell>
      <Table.Cell>{approver.fullName}</Table.Cell>
      <Table.Cell>{moment(item.created_at).format("MM/DD/YYYY")}</Table.Cell>
      <Table.Cell>
        {Boolean(item.client_approved_on) && moment(item.client_approved_on).format("YYYY-MM-DD")}
      </Table.Cell>
      <Table.Cell>
        <ul className="action-links">
          <li>
            <Link className="quote-edit" to={`/dashboard/quotes/view/${item.id}`}>
              View
            </Link>
          </li>

          {Boolean(status.key !== APPROVED && status.key !== DENIED) && (
            <li>
              <span className="btn-link" onClick={() => onDelete(item)}>
                Delete
              </span>
            </li>
          )}

          {Boolean(
            status.key !== APPROVED &&
              status.key !== DENIED &&
              status.key !== AWAITING_OCLM_APPROVAL &&
              status.key !== AWAITING_CLIENT_APPROVAL &&
              status.key !== QUOTE_COMPLETED
          ) && (
            <li>
              {!(item.total > QUOTE_PRICE_NEEDS_APPROVAL) && (
                <Link className="btn-link" to={`/dashboard/quotes/submit/${item.id}`}>
                  Submit
                </Link>
              )}
            </li>
          )}

          {Boolean(status.key === APPROVED) && (
            <li>
              <span className="btn-link" onClick={() => onComplete(item)}>
                Complete
              </span>
            </li>
          )}
        </ul>
      </Table.Cell>
    </Table.Row>
  );
}

const resetModal = () => ({
  deleted: {},
  showDeleteModal: false,
  showCompleteModal: false,
});

class List extends Component {
  state = {
    toBeCompleted: {},
    deleted: {},
    showDeleteModal: false,
    showCompleteModal: false,
    sort: {
      name: 0,
      description: 0,
    },
  };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getOCLMUsers());
  }

  toItem = (item) => {
    const { oclmusers } = this.props;

    if (!item) return;

    // const approver = oclmusers.find((user) => user.id === item.approval_user_id);
    const approver = oclmusers[item.approval_user_id];

    return (
      <QuoteItem key={item.id} approver={approver} item={item} onComplete={this.onComplete} onDelete={this.onDelete} />
    );
  };

  onDelete = (item) =>
    this.setState({
      deleted: item,
      showDeleteModal: true,
    });

  onComplete = (item) =>
    this.setState({
      toBeCompleted: item,
      showCompleteModal: true,
    });

  onDeleteConfirm = () => {
    const { id } = this.state.deleted;
    this.props.dispatch(deleteQuote(id));
    this.setState(resetModal);
  };

  onCompleteConfirm = () => {
    const { id } = this.state.toBeCompleted;
    this.props.dispatch(completeQuote(id));
    this.setState(resetModal);
  };

  sortByName = () => this.toggleSort("name");

  sortyByDescription = () => this.toggleSort("description");

  sortByStatus = () => this.toggleSort("approval_id");

  sortByID = () => this.toggleSort("id");

  sortByCreatedAt = () => this.toggleSort("created_at");

  sortByApprovedDate = () => this.toggleSort("client_approved_on");

  toggleSort = (e, key) => {
    const dir = this.state.orderDir === "asc" ? "desc" : "asc";

    this.props.onFiltersChange(e, [
      {
        name: "orderBy",
        value: key,
      },
      {
        name: "orderDir",
        value: dir,
      },
    ]);
    this.setState({
      orderBy: key,
      orderDir: dir,
    });
  };

  render() {
    const { quotes, pagination } = this.props;
    const { showDeleteModal, showCompleteModal, toBeCompleted, deleted } = this.state;

    return (
      <div className="quotes-list-container">
        <Modal open={showDeleteModal} basic>
          <Header
            icon="refresh"
            content={`Delete Quote Confirmation #${formatQuoteNumber(deleted.id, deleted.created_at)}`}
          />
          <Modal.Content>
            <p>Are you sure you want to delete this quote</p>
          </Modal.Content>
          <Modal.Actions>
            <Button basic color="red" inverted onClick={() => this.setState(resetModal)}>
              <Icon name="remove" /> No
            </Button>
            <Button color="red" inverted onClick={this.onDeleteConfirm}>
              <Icon name="checkmark" /> Yes, Delete the Quote
            </Button>
          </Modal.Actions>
        </Modal>

        <Modal open={showCompleteModal} basic>
          <Header
            icon="refresh"
            content={`Complete Quote Confirmation #${formatQuoteNumber(toBeCompleted.id, toBeCompleted.created_at)}`}
          />
          <Modal.Content>
            <p>Are you sure you want to COMPLETE this quote</p>
          </Modal.Content>
          <Modal.Actions>
            <Button color="red" onClick={() => this.setState(resetModal)}>
              <Icon name="remove" /> No
            </Button>
            <Button color="green" onClick={this.onCompleteConfirm}>
              <Icon name="checkmark" /> Yes, Complete this Quote
            </Button>
          </Modal.Actions>
        </Modal>

        <Table celled compact="very" size="small" stackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell className="quote-item-name non-sortable-header">Creator</Table.HeaderCell>

              <Table.HeaderCell className="quote-item-name non-sortable-header">Manager</Table.HeaderCell>
              <Table.HeaderCell className="quote-item-name">
                <strong>Quote #</strong>
              </Table.HeaderCell>
              <Table.HeaderCell className="quote-item-name">
                <strong className="sortable-header" onClick={(e) => this.toggleSort(e, "name")}>
                  Job Name
                </strong>
              </Table.HeaderCell>
              <Table.HeaderCell className="quote-item-name">
                <strong className="sortable-header" onClick={(e) => this.toggleSort(e, "total")}>
                  Total
                </strong>
              </Table.HeaderCell>
              <Table.HeaderCell className="quote-item-status">
                <strong className="sortable-header" onClick={(e) => this.toggleSort(e, "approval_id")}>
                  Status
                </strong>
              </Table.HeaderCell>
              <Table.HeaderCell className="quote-item-created">
                <strong className="sortable-header" onClick={(e) => this.toggleSort(e, "created_at")}>
                  Approved By
                  <br />
                  <i>If Over 2k</i>
                </strong>
              </Table.HeaderCell>
              <Table.HeaderCell className="quote-item-created">
                <strong className="sortable-header" onClick={(e) => this.toggleSort(e, "created_at")}>
                  Created On
                </strong>
              </Table.HeaderCell>
              <Table.HeaderCell className="quote-item-created sortable-header">
                <strong>Approved/Denied On</strong>
              </Table.HeaderCell>
              <Table.HeaderCell className="quote-item-action non-sortable-header">Action</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {Boolean(quotes.length) && quotes.map(this.toItem)}
            {!Boolean(quotes.length) && (
              <Table.Row>
                <Table.Cell colSpan={7}>
                  <b>There are 0 Quotes. Please Create a Quote to get started.</b>
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>

        <Pagination {...pagination} location={this.props.location} />
      </div>
    );
  }
}

export default connect()(List);
