import React, { PropTypes } from "react";
import Dropzone from "react-dropzone";
import Previews from "./Previews";
import "./Uploader.css";

export default class Uploader extends React.Component {
  get previews() {
    return this.state.previews;
  }

  onDrop = (acceptedFiles, rejectedFiles) => {
    if (acceptedFiles) {
      console.log("acceptedFiles", acceptedFiles);
      this.props.onDrop(acceptedFiles);
    }
    if (rejectedFiles) {
      console.log("rejectedFiles", rejectedFiles);
    }
  };

  render() {
    const { className, images } = this.props;

    return (
      <div className={`uploader-container ${className}`}>
        <Dropzone accept="image/jpeg, image/png" className="dropzone-uploader" onDrop={this.onDrop} maxSize={10485760}>
          Click or Drag on Images to Upload
        </Dropzone>

        {!!images.length && (
          <Previews items={images} onClose={this.props.onClose} onAddCaption={this.props.onAddCaption} />
        )}
      </div>
    );
  }
}

Uploader.defaultProps = {
  images: [],
  onUpload() {},
};

Uploader.propTypes = {
  onUpload: PropTypes.func,
};
