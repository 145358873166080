import React from "react";
import axios from "axios";
import PropTypes from "prop-types";
import debounce from "lodash/debounce";
import querystring from "querystring";
import cookie from "react-cookie";
import { Search } from "semantic-ui-react";

import "./ManagerSearch.css";

export default class ManagerSearch extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    onManagerSelect: PropTypes.func,
    assigned_to: PropTypes.number
  };

  state = {
    authorized: false,
    isLoading: false,
    value: "",
    results: []
  };

  componentDidMount() {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/token`, {
        headers: {
          Authorization: `Bearer ${cookie.load("token")}`
        }
      })
      .then(resp => {
        const roles = resp.data.roles;

        if (roles.includes("manager_read")) {
          this.setState({
            authorized: true
          });
        }
      });
  }

  makeSearch = searchData => {
    const { value } = searchData;
    const { assigned_to } = this.props;
    const query = querystring.stringify({
      filter: JSON.stringify({
        name: value
      })
    });

    return axios
      .get(`${process.env.REACT_APP_PORTAL_API_URL}/manager?${query}`, {
        headers: {
          Authorization: `Bearer ${cookie.load("token")}`
        }
      })
      .then(resp => {
        return resp.data
          .filter(result => result.id !== assigned_to)
          .map((result, idx) => {
            return {
              title: result.name,
              description: result.management.company_name,
              id: result.id,
              name: result.name,
              company_name: result.management.company_name
            };
          });
      });
  };

  handleSearchChange = (e, value) => {
    this.setState({ isLoading: true, value });

    this.makeSearch(value).then(data => {
      this.setState({
        isLoading: false,
        results: data
      });
    });
  };

  handleResultSelect = (e, data) => {
    this.props.onManagerSelect(e, data.result);
  };

  renderUnauthorized = () => {
    return (
      <div>
        <h3>Add Additional Approvers</h3>
        <p>Your user does not have the ability to read manager data, therefore you can't add additional approvers</p>
        <p>If you wish to add additional approvers, please consult your manager for Portal Approval</p>
      </div>
    );
  };

  render() {
    const { isLoading, value, results, authorized } = this.state;
    const { title } = this.props;

    if (!authorized) {
      return this.renderUnauthorized();
    }
    return (
      <div>
        {title && <h3>{title}</h3>}
        <Search
          loading={isLoading}
          onResultSelect={this.handleResultSelect}
          onSearchChange={debounce(this.handleSearchChange, 500, {
            leading: true
          })}
          results={results}
          value={value ? value.value : null}
        />
        <div className="quote-select-user" />
      </div>
    );
  }
}

ManagerSearch.defaultProps = {
  onChange() {},
  onManagerSelect() {}
};
