import React, { Component } from "react";

import n from "numeral";
import get from "lodash/get";
import { Tab, Table } from "semantic-ui-react";
import QuarterChart from "../../components/QuarterChart";
import DashboardTable from "./DashboardTable";
import MonthChartByCount from "../../components/MonthChartByCount";

const today = new Date();
const lastYearToday = today;

const thisYear = today.getFullYear();

lastYearToday.setFullYear(today.getFullYear() - 1);
const lastYear = lastYearToday.getFullYear();

export default class SupervisorPanel extends Component {
  renderTabPanes = () => {
    const { counts } = this.props;

    return [
      {
        menuItem: `Approved`,
        render: () => (
          <Tab.Pane>
            <div className="counts-container ">
              <div className="item report-item">
                <MonthChartByCount
                  className="month-chart"
                  title="Approved Quote Count by Month"
                  color="#17a538"
                  data={get(counts, "approved.byMonth")}
                  type={"count"}
                  hintSuffix="Quotes"
                  hintRender={value => (
                    <div>
                      <h3>{value.x}</h3>
                      {value.y} Quotes
                    </div>
                  )}
                  yearType="current"
                />
              </div>
              <div className="item report-item">
                <MonthChartByCount
                  className="month-chart"
                  title="Approved Quote Totals by Month"
                  color="#0dd53b"
                  data={get(counts, "approved.byMonth")}
                  type={"total"}
                  hintSuffix=""
                  hintRender={value => (
                    <div>
                      <h3>{value.x}</h3>
                      {n(value.y).format("$0,0.00")}
                    </div>
                  )}
                  yearType="current"
                />
              </div>
            </div>

            <div className="counts-container ">
              <div className="item report-item">
                <QuarterChart
                  color="#17a538"
                  data={get(counts, "approved.counts.current")}
                  title={`Approved Quotes by Quarter ${thisYear}`}
                />
              </div>
              <div className="item report-item">
                <QuarterChart
                  color="#17a538"
                  data={get(counts, "approved.counts.last")}
                  title={`Approved Quotes by Quarter ${lastYear}`}
                />
              </div>
            </div>
            <Table>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>Approved Q1 {thisYear} Quote Total: </Table.Cell>
                  <Table.Cell width={3}>
                    {n(get(counts, "approved.counts.current.q1.total", 0)).format("$0,0.00")}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Approved Q2 {thisYear} Quote Total: </Table.Cell>
                  <Table.Cell width={3}>
                    {n(get(counts, "approved.counts.current.q2.total", 0)).format("$0,0.00")}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Approved Q3 {thisYear} Quote Total: </Table.Cell>
                  <Table.Cell width={3}>
                    {n(get(counts, "approved.counts.current.q3.total", 0)).format("$0,0.00")}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Approved Q4 {thisYear} Quote Total: </Table.Cell>
                  <Table.Cell width={3}>
                    {n(get(counts, "approved.counts.current.q4.total", 0)).format("$0,0.00")}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell positive>Approved Quote Total by Year {thisYear}: </Table.Cell>
                  <Table.Cell positive width={3}>
                    {n(get(counts, "approved.counts.current.year.total", 0)).format("$0,0.00")}
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Tab.Pane>
        )
      },
      {
        menuItem: `Open`,
        render: () => (
          <Tab.Pane>
            <div className="counts-container ">
              <div className="item report-item">
                <MonthChartByCount
                  className="month-chart"
                  title="Open Quote Count by Month"
                  color="#F2711C"
                  data={get(counts, "open.byMonth")}
                  type={"count"}
                  hintSuffix="Quotes"
                  hintRender={value => (
                    <div>
                      <h3>{value.x}</h3>
                      {value.y} Quotes
                    </div>
                  )}
                  yearType="current"
                />
              </div>
              <div className="item report-item">
                <MonthChartByCount
                  className="month-chart"
                  title="Open Quote Total by Month"
                  color="#ff9c5a"
                  data={get(counts, "open.byMonth")}
                  type={"total"}
                  hintSuffix=""
                  hintRender={value => (
                    <div>
                      <h3>{value.x}</h3>
                      {n(value.y).format("$0,0.00")}
                    </div>
                  )}
                  yearType="current"
                />
              </div>
            </div>

            <div className="counts-container ">
              <div className="item report-item">
                <QuarterChart
                  color="#F2711C"
                  data={get(counts, "open.counts.current")}
                  title={`Open Quotes by Quarter ${thisYear}`}
                />
              </div>
              <div className="item report-item">
                <QuarterChart
                  color="#F2711C"
                  data={get(counts, "open.counts.last")}
                  title={`Open Quotes by Quarter ${lastYear}`}
                />
              </div>
            </div>
            <Table>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>Open Q1 {thisYear} Quote Total: </Table.Cell>
                  <Table.Cell width={3}>
                    {n(get(counts, "open.counts.current.q1.total", 0)).format("$0,0.00")}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Open Q2 {thisYear} Quote Total: </Table.Cell>
                  <Table.Cell width={3}>
                    {n(get(counts, "open.counts.current.q2.total", 0)).format("$0,0.00")}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Open Q3 {thisYear} Quote Total: </Table.Cell>
                  <Table.Cell width={3}>
                    {n(get(counts, "open.counts.current.q3.total", 0)).format("$0,0.00")}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Open Q4 {thisYear} Quote Total: </Table.Cell>
                  <Table.Cell width={3}>
                    {n(get(counts, "open.counts.current.q4.total", 0)).format("$0,0.00")}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell positive>Open Quote Total by Year {thisYear}: </Table.Cell>
                  <Table.Cell positive width={3}>
                    {n(get(counts, "open.counts.current.year.total", 0)).format("$0,0.00")}
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Tab.Pane>
        )
      },
      {
        menuItem: `Denied`,
        render: () => (
          <Tab.Pane>
            <div className="counts-container ">
              <div className="item report-item">
                <MonthChartByCount
                  className="month-chart"
                  title="Denied Quote Count by Month"
                  color="#840000"
                  data={get(counts, "denied.byMonth")}
                  type={"count"}
                  hintSuffix="Quotes"
                  hintRender={value => (
                    <div>
                      <h3>{value.x}</h3>
                      {value.y} Quotes
                    </div>
                  )}
                  yearType="current"
                />
              </div>
              <div className="item report-item">
                <MonthChartByCount
                  className="month-chart"
                  title="Denied Quote Total by Month"
                  color="#c00202"
                  data={get(counts, "denied.byMonth")}
                  type={"total"}
                  hintSuffix=""
                  hintRender={value => (
                    <div>
                      <h3>{value.x}</h3>
                      {n(value.y).format("$0,0.00")}
                    </div>
                  )}
                  yearType="current"
                />
              </div>
            </div>

            <div className="counts-container ">
              <div className="item report-item">
                <QuarterChart
                  color="#840000"
                  data={get(counts, "denied.counts.current")}
                  title={`Denied Quotes by Quarter ${thisYear}`}
                />
              </div>
              <div className="item report-item">
                <QuarterChart
                  color="#840000"
                  data={get(counts, "denied.counts.last")}
                  title={`Denied Quotes by Quarter ${lastYear}`}
                />
              </div>
            </div>
            <Table>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>Denied Q1 {thisYear} Quote Total: </Table.Cell>
                  <Table.Cell width={3}>
                    {n(get(counts, "denied.counts.current.q1.total", 0)).format("$0,0.00")}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Denied Q2 {thisYear} Quote Total: </Table.Cell>
                  <Table.Cell width={3}>
                    {n(get(counts, "denied.counts.current.q2.total", 0)).format("$0,0.00")}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Denied Q3 {thisYear} Quote Total: </Table.Cell>
                  <Table.Cell width={3}>
                    {n(get(counts, "denied.counts.current.q3.total", 0)).format("$0,0.00")}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Denied Q4 {thisYear} Quote Total: </Table.Cell>
                  <Table.Cell width={3}>
                    {n(get(counts, "denied.counts.current.q4.total", 0)).format("$0,0.00")}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell negative>Denied Quote Total by Year {thisYear}: </Table.Cell>
                  <Table.Cell negative width={3}>
                    {n(get(counts, "denied.counts.current.year.total", 0)).format("$0,0.00")}
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Tab.Pane>
        )
      },
      {
        menuItem: `Completed`,
        render: () => (
          <Tab.Pane>
            <div className="counts-container ">
              <div className="item report-item">
                <MonthChartByCount
                  className="month-chart"
                  title="Completed Quote Count by Month"
                  color="#00B5AD"
                  data={get(counts, "completed.byMonth")}
                  type={"count"}
                  hintSuffix="Quotes"
                  hintRender={value => (
                    <div>
                      <h3>{value.x}</h3>
                      {value.y} Quotes
                    </div>
                  )}
                  yearType="current"
                />
              </div>
              <div className="item report-item">
                <MonthChartByCount
                  className="month-chart"
                  title="Completed Quote Total by Month"
                  color="#008681"
                  data={get(counts, "completed.byMonth")}
                  type={"total"}
                  hintSuffix=""
                  hintRender={value => (
                    <div>
                      <h3>{value.x}</h3>
                      {n(value.y).format("$0,0.00")}
                    </div>
                  )}
                  yearType="current"
                />
              </div>
            </div>

            <div className="counts-container ">
              <div className="item report-item">
                <QuarterChart
                  color="#00B5AD"
                  data={get(counts, "completed.counts.current")}
                  title={`Completed Quotes for ${thisYear}`}
                />
              </div>
              <div className="item report-item">
                <QuarterChart
                  color="#00B5AD"
                  data={get(counts, "completed.counts.last")}
                  title={`Completed Quotes for ${lastYear}`}
                />
              </div>
            </div>
            <Table>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>Completed Q1 {thisYear} Quote Total: </Table.Cell>
                  <Table.Cell width={3}>
                    {n(get(counts, "completed.counts.current.q1.total", 0)).format("$0,0.00")}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Completed Q2 {thisYear} Quote Total: </Table.Cell>
                  <Table.Cell width={3}>
                    {n(get(counts, "completed.counts.current.q2.total", 0)).format("$0,0.00")}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Completed Q3 {thisYear} Quote Total: </Table.Cell>
                  <Table.Cell width={3}>
                    {n(get(counts, "completed.counts.current.q3.total", 0)).format("$0,0.00")}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Completed Q4 {thisYear} Quote Total: </Table.Cell>
                  <Table.Cell width={3}>
                    {n(get(counts, "completed.counts.current.q4.total", 0)).format("$0,0.00")}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell positive>Completed Quote Total by Year {thisYear}: </Table.Cell>
                  <Table.Cell width={3} positive>
                    {n(get(counts, "completed.counts.current.year.total", 0)).format("$0,0.00")}
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Tab.Pane>
        )
      }
    ];
  };

  render() {
    const { counts } = this.props;
    return (
      <div>
        <Tab panes={this.renderTabPanes()} />
        <DashboardTable
          title={"All Open Quotes"}
          data={get(counts, "open.quotes", [])}
          color="orange"
          description="Quotes that are sent to client"
          expandable={true}
        />
      </div>
    );
  }
}
