import { QUOTE_PRICE_NEEDS_APPROVAL } from "../../constants";

export function validateApprovalID(approval_user_id, total, name) {
  if (total >= QUOTE_PRICE_NEEDS_APPROVAL && !approval_user_id) {
    return "You Need to Select an Approval Manager";
  }
}

// validate customer id
export function validateCustomerID(customer, portal_manager_id, company_id, is_adding_new_manager) {
  // if were adding a new manager, dont need to validate here
  if (is_adding_new_manager) {
    return undefined;
  }
  return !portal_manager_id && !customer ? "Need to Associate a Customer" : undefined;
}

// validate customer (includes management)
export function validateCustomer(customer, portal_manager_id, company_id, is_adding_new_manager) {
  // if (portal_manager_id === null && customer === null && company_id === null) {
  //   return "A customer must be associated";
  // }

  if (portal_manager_id) return;

  if (is_adding_new_manager) {
    // return !customer || !customer[field] ? "Customer Name Required" : undefined;
    return {
      name: !customer || !customer.name ? "Customer Name Required" : undefined,
      email: !customer || !customer.email ? "Customer Email Required" : undefined,
      phone: !customer || !customer.phone ? "Customer Phone Required" : undefined,
    };
  }

  return;
}

// validate company ID
export function validateCompanyID(customer, portal_manager_id, company_id, is_adding_new_manager) {
  if (is_adding_new_manager && !company_id) {
    return "A company must be associated";
  }
  if (company_id === null && customer === null && portal_manager_id === null) {
    return "A company must be associated";
  }
  return undefined;
}

// validate quotes
export function validateQuotes(quotes) {
  if (!quotes || !quotes.length) {
    return "You Need at least 1 Quote Item";
  }

  return quotes.map((obj) => {
    const { description, quantity, type, price } = obj;

    return {
      description: !description ? "Description Required" : undefined,
      quantity:
        !quantity && quantity !== 0 ? "Quantity Required" : isNaN(quantity) ? "Quantity must be a number" : undefined,
      type: !type ? "Type Required" : undefined,
      price: !price && price !== 0 ? "Price Required" : isNaN(price) ? "Price must be a number" : undefined,
    };
  });
}
