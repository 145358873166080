import { normalize } from "normalizr";
import axios from "axios";
import cookie from "react-cookie";
import assign from "lodash/assign";

const API_ROOT = `${process.env.REACT_APP_API_URL}/api`;

// Fetches an API response and normalizes the result JSON according to schema.
// This makes every API response have the same shape, regardless of how nested it was.
const callApi = (endpoint, method, data, authenticated, schema, external = false) => {
  const url = external ? endpoint : API_ROOT + endpoint;

  let headers = {};

  if (authenticated) {
    headers = assign({}, headers, {
      Authorization: `Bearer ${cookie.load("token")}`
    });
    // console.log('Auth: ', `Bearer ${cookie.load('token')}`);
  }

  return axios({
    headers,
    method,
    url,
    data
  }).then(response => {
    const data = response.data.payload || response.data;
    if (schema) return normalize(data, schema);
    return data;
  });
};

// Action key that carries API call info interpreted by this Redux middleware.
export const CALL_API = Symbol("Call API");

// A Redux middleware that interprets actions with CALL_API info specified.
// Performs the call and promises when such actions are dispatched.
export default store => next => action => {
  // So the middleware doesn't get applied to every single action
  if (!action.meta || typeof action.meta.api === "undefined") return next(action);

  const { payload } = action;
  let { endpoint, onSuccess, onFailure } = action.meta;

  const { schema, types, method, authenticated, external } = action.meta;

  if (typeof endpoint !== "string") {
    throw new Error("Specify a string endpoint URL.");
  }
  // if (!schema) {
  //   throw new Error('Specify one of the exported Schemas.')
  // }
  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error("Expected an array of three action types.");
  }
  if (!types.every(type => typeof type === "string")) {
    throw new Error("Expected action types to be strings.");
  }

  // dont need to merge in this data
  // const actionWith = data => {
  //   const finalAction = Object.assign({}, action, data)
  //   delete finalAction[CALL_API]
  //   return finalAction
  // }

  const [requestType, successType, failureType] = types;
  next({ type: requestType });

  return callApi(endpoint, method, payload, authenticated, schema, external)
    .then(response => {
      next({
        response,
        type: successType,
        meta: { onSuccess }
      });
      return response;
    })
    .catch(error => {
      console.log("error", error);
      next({
        type: failureType,
        error: error.message || "Something bad happened",
        meta: { onFailure }
      });
      throw new Error(error);
    });
};
