import React, { Component } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import axios from "axios";
import { Button, Dropdown } from "semantic-ui-react";
import cookie from "react-cookie";

import * as OPTIONS from "./options";

import "./QuoteFilters.css";

function makeRequest(url) {
  const options = {
    headers: {
      Authorization: `Bearer ${cookie.load("token")}`
    }
  };

  return axios.get(url, options);
}

class QuoteFilters extends Component {
  state = {
    users: [],
    managers: []
  };
  componentDidMount() {
    Promise.all([
      makeRequest(`${process.env.REACT_APP_API_URL}/api/oclmusers`),
      makeRequest(`${process.env.REACT_APP_API_URL}/api/managers`)
    ]).then(([users, managers]) => {
      this.setState({
        users: users.data,
        managers: managers.data
      });
    });
  }
  render() {
    const { open, onChange, onReset } = this.props;
    const { status, range, oclmuser, manager } = this.props;

    const { users, managers } = this.state;

    const userOptions = OPTIONS.formatUsers(users);
    const managerOptions = OPTIONS.formatManagers(managers);

    return (
      <div
        className={`${cx(
          {
            open: open
          },
          "filters-panel"
        )}`}
      >
        <Dropdown
          value={status ? Number(status) : undefined}
          name="status"
          onChange={onChange}
          placeholder="Approval Status"
          search
          selection
          options={OPTIONS.APPROVAL_OPTIONS}
        />

        <Dropdown
          value={range ? range : undefined}
          name="range"
          onChange={onChange}
          placeholder="Date Range"
          search
          selection
          options={OPTIONS.DATE_RANGE}
        />

        <Dropdown
          disabled={userOptions.length > 0 ? false : true}
          value={oclmuser ? Number(oclmuser) : undefined}
          name="oclmuser"
          onChange={onChange}
          placeholder="Quote Creator"
          search
          selection
          options={userOptions}
        />

        <Dropdown
          disabled={managers.length > 0 ? false : true}
          value={manager ? Number(manager) : undefined}
          name="manager"
          onChange={onChange}
          placeholder="Assigned To"
          search
          selection
          options={managerOptions}
        />

        <Button color="red" inverted onClick={onReset}>
          Reset
        </Button>
      </div>
    );
  }
}

QuoteFilters.propTypes = {
  open: PropTypes.bool
};

export default QuoteFilters;
