export const API_URL = process.env.REACT_APP_API_URL || "http://localhost:4010";

export const IMAGES_HOST = "https://oclm.imgix.net";

export const AWAITING_OCLM_APPROVAL = "AWAITING_OCLM_APPROVAL";

export const AWAITING_CLIENT_APPROVAL = "AWAITING_CLIENT_APPROVAL";

export const APPROVED = "APPROVED";

export const DENIED = "DENIED";

export const IN_PROGRESS = "IN_PROGRESS";

export const IN_PROGRESS_APPROVED = "IN_PROGRESS_APPROVED";

export const SENT_TO_CLIENT = "SENT_TO_CLIENT";

export const MANAGER_APPROVED = "MANAGER_APPROVED";

export const DRAFT = "DRAFT";

export const QUOTE_COMPLETED = "QUOTE_COMPLETED";

export const FILTER_RESET = "FILTER_RESET";

export const QUOTE_PRICE_NEEDS_APPROVAL = 2000;

export const LOGIN_CHECK_MS = 1800000;

export const APPROVAL_TYPES = {
  1: SENT_TO_CLIENT,
  2: APPROVED,
  3: DENIED,
  4: AWAITING_OCLM_APPROVAL,
  5: MANAGER_APPROVED,
  6: QUOTE_COMPLETED,
};
