import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import { Checkbox, Divider, Grid, Button, Form, Table } from "semantic-ui-react";
import { createQuoteMessage, updateQuoteMessage } from "../../actions/quote_messages";
import "./QuoteNotes.css";

class QuoteNotes extends React.Component {
  static defaultProps = {
    messages: [],
    onAddNote() {}
  };

  state = {
    message: ""
  };

  onAddNote = e => {
    e.preventDefault();
    const { message } = this.state;
    const { dispatch, quote_id } = this.props;

    if (message === "") {
      alert("you need to enter a message");
      return;
    }

    this.setState({
      message: ""
    });

    dispatch(
      createQuoteMessage({
        quote_id,
        message
      })
    ).then(() => this.props.onAddNote());
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  };

  onCheckboxChange = (e, id, data) => {
    const { dispatch } = this.props;
    const payload = {
      include_in_quote: data.checked
    };
    dispatch(updateQuoteMessage(id, payload));
  };

  toItem = message => {
    if (!message) {
      return <div />;
    }
    return (
      <Table.Row key={message.id}>
        <Table.Cell>{moment(message.created_at).format("YYYY-MM-DD, h:mm:ss a")}</Table.Cell>
        <Table.Cell>{message.name}</Table.Cell>
        <Table.Cell>{message.message}</Table.Cell>
        <Table.Cell textAlign="center">
          <Checkbox
            defaultChecked={Boolean(message.include_in_quote)}
            onChange={(e, data) => this.onCheckboxChange(e, message.id, data)}
          />
        </Table.Cell>
      </Table.Row>
    );
  };

  render() {
    return (
      <div className={"quote-messages-container"}>
        <h3>Quote Discussion</h3>
        <p>Messages betwen the Client and OCLM will go here.</p>

        <Divider />

        <Table celled size="small" stackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={2}>Date</Table.HeaderCell>
              <Table.HeaderCell width={5}>Name</Table.HeaderCell>
              <Table.HeaderCell width={9}>Message</Table.HeaderCell>
              <Table.HeaderCell width={2}>Include in Quote?</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {Boolean(this.props.messages.length) && this.props.messages.map(this.toItem)}
            {this.props.messages.length === 0 && (
              <p style={{ padding: "1rem" }}>There hasnt been any Quote Discussion Yet</p>
            )}
          </Table.Body>
        </Table>

        <Divider />

        <h3>Add Message</h3>
        <Form onSubmit={this.onAddNote}>
          <Grid stackable>
            <Grid.Row>
              <Grid.Column width={2} />

              <Grid.Column width={11}>
                <Form.TextArea
                  name={"message"}
                  label="Quote Notes"
                  placeholder="Enter in notes"
                  value={this.state.message}
                  onChange={this.handleChange}
                />
              </Grid.Column>
              <Grid.Column width={3}>
                <Button primary>Add Message</Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    messageEntities: state.entities.messages
  };
}

export default connect(mapStateToProps)(QuoteNotes);
