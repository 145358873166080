import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { Table } from 'semantic-ui-react'
import { deletePurchaseOrder } from '../../actions/purchase_orders';
import './PurchaseOrders.css';

function PurchaseOrderItem({ item, onDelete }) {
  return (
    <Table.Row>
      <Table.Cell>
        <Link className="quote-edit" to={`/dashboard/purchase-orders/view/${item.id}`}>{item.name}</Link>
      </Table.Cell>
      <Table.Cell className="quote-item-description">
        {item.description}
      </Table.Cell>
      <Table.Cell className="quote-item-status">
        {item.approval_id
          ? (<div className="btn-approval-needed">Needs Approval</div>)
          : (<div className="btn-in-progress">In Progress</div>)}
      </Table.Cell>
      <Table.Cell>
        <Link className="quote-edit" to={`/dashboard/purchase-orders/view/${item.id}`}>View</Link> |
        <Link className="quote-edit" to={`/dashboard/purchase-orders/edit/${item.id}`}>Edit</Link> |
        <button className="btn-link" onClick={() => onDelete(item.id, item.name)}>Delete</button>
      </Table.Cell>
    </Table.Row>
  );
}


class PurchaseOrderList extends Component {

  constructor(){
    super();
    this.toItem = this.toItem.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }

  toItem(id) {
    const { purchase_orders } = this.props;
    const item = purchase_orders[id];
    if (!item) return;
    console.log('toItem ', id, item);
    return <PurchaseOrderItem key={id} item={item} onDelete={this.onDelete} />;
  }

  onDelete(id, name) {
    const res = window.confirm(`Are you sure you want to delete: ${name}?`)
    if (res) {
      this.props.dispatch(deletePurchaseOrder(id));
    }
  }

  render() {
    const { purchaseOrderIds } = this.props;

    return (
      <div>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell className="quote-item-name">Name</Table.HeaderCell>
              <Table.HeaderCell className="quote-item-description">Description</Table.HeaderCell>
              <Table.HeaderCell className="quote-item-status">Status</Table.HeaderCell>
              <Table.HeaderCell className="quote-item-action">Action</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {purchaseOrderIds.map(this.toItem)}
          </Table.Body>
        </Table>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { purchase_orders, entities } = state;

  return {
    purchase_orders: entities.purchase_orders,
    purchaseOrderIds: purchase_orders.purchaseOrderIds || [],
  }
}

export default connect(mapStateToProps)(PurchaseOrderList);
