import React from "react";
import PropTypes from "prop-types";
import { Button } from "semantic-ui-react";
import { generateLinks, deleteShareLink } from "../../actions/quote-approvers";
import { copyToClipboard } from "../../utils/copy";

import "./AdditionalApprovers.css";

export default class AdditionalApprovers extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    quote_id: PropTypes.number,
    getQuoteById: PropTypes.func,
  };

  copyText = null;

  onDeleteShareLink = (item) => {
    console.log("item", item);
    const { dispatch } = this.props;
    dispatch(deleteShareLink(item.id));
  };

  onCopy = (item) => {
    copyToClipboard(item.shortlink, this.copyText);
  };

  onGenerateShareLink = (item) => {
    const { quote, dispatch, getQuoteById } = this.props;

    const availableManagers = [item.manager_id];

    dispatch(
      generateLinks(quote.id, {
        managerIds: availableManagers,
      })
    ).then(() => {
      dispatch(getQuoteById(quote.id));
    });
  };

  render() {
    const { managers } = this.props;
    return (
      <div className="additional-approvers-container">
        <h3>Additional Approvers</h3>
        {Boolean(managers.length) && (
          <div className="users">
            <p>The following users have the ability to Accept or Reject this Quote</p>
            {managers.map((item) => (
              <div className="user">
                <label className="name">{item.manager.name}</label>
                <p className="email">
                  {item.manager.management.company_name}
                  <br />
                  {item.manager.email}
                  {item.shortlink && (
                    <div>
                      <input
                        contenteditable
                        type="text"
                        ref={(d) => {
                          this.copyText = d;
                        }}
                        style={{ background: "none", border: "none" }}
                        value={item.shortlink}
                      ></input>
                    </div>
                  )}
                  {item.shortlink && (
                    <div style={{ display: "flex", flexDirection: "row", marginTop: "1rem" }}>
                      <Button
                        size="small"
                        color={"yellow"}
                        onClick={() => {
                          this.onCopy(item);
                        }}
                      >
                        Copy Link
                      </Button>

                      <Button
                        size="small"
                        color={"red"}
                        onClick={() => {
                          this.onDeleteShareLink(item);
                        }}
                      >
                        Delete
                      </Button>
                    </div>
                  )}
                  {!item.public_access_token && (
                    <div style={{ display: "flex", flexDirection: "row", marginTop: "1rem" }}>
                      <Button
                        size="small"
                        color={"yellow"}
                        onClick={() => {
                          this.onGenerateShareLink(item);
                        }}
                      >
                        Generate Share Link
                      </Button>

                      <Button
                        size="small"
                        color={"red"}
                        onClick={() => {
                          this.onDeleteShareLink(item);
                        }}
                      >
                        Delete
                      </Button>
                    </div>
                  )}
                </p>
              </div>
            ))}
          </div>
        )}
        {!Boolean(managers.length) && (
          <span>
            <p>There are no Additional Approvers for this Quote</p>
          </span>
        )}
      </div>
    );
  }
}

AdditionalApprovers.defaultProps = {
  managers: [],
};
