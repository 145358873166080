import { createAPIAction } from "redux-actions-api-addon";
import { Schemas } from "../schemas";

export const getRegionalManagers = createAPIAction(
  "REGIONAL_MANAGERS_SEARCH",
  "GET",
  () => `/regionalmanagers`,
  (data) => data,
  () => ({
    authenticated: true,
  })
);

export const getOCLMUsers = createAPIAction(
  "OCLM_USERS",
  "GET",
  (id) => `${process.env.REACT_APP_PORTAL_API_URL}/user?sort=%5B%22id%22,%22DESC%22%5D&pageSize=4000`,
  null,
  () => ({
    external: true,
    authenticated: true,
    // schema: Schemas.USER,
  })
);

export const getUserById = createAPIAction(
  "USER_BY_ID",
  "GET",
  (id) => `${process.env.REACT_APP_PORTAL_API_URL}/user/${id}`,
  null,
  () => ({
    external: true,
    authenticated: true,
    schema: Schemas.USER,
  })
);
