import React from "react";
import PropTypes from "prop-types";
import findIndex from "lodash/findIndex";
import uniq from "lodash/uniq";
import { Link } from "react-router";
import { connect } from "react-redux";
import { Breadcrumb, Button, Tab, Table, Grid, Divider, Header, Form } from "semantic-ui-react";
import { formatQuoteNumber } from "../../utils/quotes";
import { submitQuote, getQuoteById } from "../../actions/quotes";
import ManagerSearch from "../../components/ManagerSearch/ManagerSearch";
import QuoteStatus from "../../components/QuoteStatus";
import GenerateShareLinks from "../GenerateShareLinks";
import { denormalizeQuote } from "../../utils/denormalizers";
import { getApprovalStatus } from "../../utils/quotes";
import { AWAITING_CLIENT_APPROVAL } from "../../constants";

class SubmitQuote extends React.Component {
  static propTypes = {
    name: PropTypes.string
  };

  state = {
    email: "",
    additional_managers: [],
    ccMe: false,
    sendLinks: false,
    isSending: false
  };

  componentDidMount() {
    this.props.dispatch(getQuoteById(this.props.params.id));
  }

  onHandleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleCheckboxChange = e => {
    const target = e.target;
    const value = target.checked;
    this.setState({
      [e.target.name]: value
    });
  };

  onHandleManagerSelect = (e, data) => {
    this.setState({
      additional_managers: [...this.state.additional_managers, data]
    });
  };

  onManagerRemove = data => {
    const index = findIndex(this.state.additional_managers, ["id", data.id]);
    this.setState({
      additional_managers: [
        ...this.state.additional_managers.slice(0, index),
        ...this.state.additional_managers.slice(index + 1)
      ]
    });
  };

  onSend = e => {
    e.preventDefault();

    const { quote } = this.props;
    const { additional_managers, message, ccMe, isSending } = this.state;

    if (isSending) {
      return true;
    }

    const additionalManagerIDs = uniq(additional_managers.map(manager => manager.id));

    const payload = {
      ccMe,
      quote_id: quote.id,
      message,
      additional_managers: additionalManagerIDs
    };

    this.props.dispatch(submitQuote(payload));

    this.setState({
      isSending: true
    });
  };

  render() {
    const { additional_managers } = this.state;
    const { dispatch, created_at, customer = {}, portal_manager_id, quote } = this.props;

    // guard
    if (!quote) {
      return <div />;
    }

    const approvalStatus = getApprovalStatus(quote.approval_id, quote.approval_user_id, quote.manager_approved_on);

    return (
      <Grid padded>
        <Grid.Row>
          <Grid.Column>
            <div className="quote-top-section">
              <Breadcrumb className="quote-breadcrumbs">
                <Breadcrumb.Section link>Home</Breadcrumb.Section>
                <Breadcrumb.Divider icon="right angle" />
                <Breadcrumb.Section link>
                  <Link to="/dashboard/quotes">Quotes</Link>
                </Breadcrumb.Section>
                <Breadcrumb.Divider icon="right angle" />
                <Breadcrumb.Section active>
                  {quote.id && (
                    <Link to={`/dashboard/quotes/view/${quote.id}`}>
                      View {formatQuoteNumber(quote.id, created_at)}
                    </Link>
                  )}
                </Breadcrumb.Section>
              </Breadcrumb>
            </div>

            <Header as="h1">Submit Quote</Header>
            <QuoteStatus quote={quote} />

            <Tab
              style={{ marginTop: "3rem" }}
              panes={[
                {
                  menuItem: "Send via Email",
                  render: () => (
                    <Tab.Pane>
                      {approvalStatus.key === AWAITING_CLIENT_APPROVAL && <p>Quote has been sent to client</p>}
                      {approvalStatus.key !== AWAITING_CLIENT_APPROVAL && (
                        <div>
                          <p>Add in a receipient for this quote and it will be automatically delivered.</p>

                          <Form>
                            <Form.Field>
                              <Header>Manager on Quote: {customer.name}</Header>
                              <Header as="h4">Manager Email: {customer.email}</Header>
                              <p>
                                Quotes sent to the following manager will be given the ability to accept/reject the
                                quote via the OCLM Customer Portal. If they do not have an account, they will be asked
                                to create one.
                              </p>
                            </Form.Field>
                          </Form>
                          <Form>
                            <Form.Field
                              name={"message"}
                              onChange={this.onHandleChange}
                              label="Enter a message"
                              control="textarea"
                              rows="3"
                            />

                            <ManagerSearch
                              title="Add Additional Approvers"
                              onManagerSelect={this.onHandleManagerSelect}
                              assigned_to={portal_manager_id}
                            />

                            {Boolean(additional_managers.length) && (
                              <div style={{ marginTop: "2rem" }}>
                                <p>The following users will have the ability to Approve or Deny the quote</p>
                                <Table>
                                  <Table.Header>
                                    <Table.HeaderCell>Manager Name</Table.HeaderCell>
                                    <Table.HeaderCell>Management Company</Table.HeaderCell>
                                    <Table.HeaderCell>Action</Table.HeaderCell>
                                  </Table.Header>
                                  {Boolean(additional_managers.length) &&
                                    additional_managers.map(manager => (
                                      <Table.Row columns={2}>
                                        <Table.Cell>{manager.name}</Table.Cell>
                                        <Table.Cell>{manager.company_name}</Table.Cell>
                                        <Table.Cell>
                                          <span
                                            className="remove"
                                            onClick={() => {
                                              this.onManagerRemove(manager);
                                            }}
                                          >
                                            Remove
                                          </span>
                                        </Table.Cell>
                                      </Table.Row>
                                    ))}
                                </Table>
                              </div>
                            )}
                            {!Boolean(additional_managers.length) && (
                              <p>There are currently no additional managers selected for approval</p>
                            )}

                            <div style={{ margin: "2rem 0" }}>
                              <h3>CC Yourself?</h3>
                              <div className="ui checkbox">
                                <input
                                  type="checkbox"
                                  readonly=""
                                  name="ccMe"
                                  tabindex="0"
                                  checked={this.state.ccMe}
                                  onChange={this.handleCheckboxChange}
                                />
                                <label>Send a copy to yourself</label>
                              </div>
                            </div>

                            <Button
                              disabled={this.state.isSending}
                              className="submit-quote-button"
                              primary
                              type="submit"
                              onClick={this.onSend}
                            >
                              Submit Quote
                            </Button>
                          </Form>
                        </div>
                      )}
                    </Tab.Pane>
                  )
                },
                {
                  menuItem: "Generate Share Links",
                  render: () => (
                    <Tab.Pane>
                      <GenerateShareLinks dispatch={dispatch} quote={quote} />
                    </Tab.Pane>
                  )
                }
              ]}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

SubmitQuote.defaultProps = {
  customers: {}
};

function mapStateToProps(state, ownProps) {
  const quote = denormalizeQuote(state, ownProps.params.id);

  return {
    quote
  };
}
export default connect(mapStateToProps)(SubmitQuote);
