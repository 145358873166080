import React from 'react';
import { FormInput } from 'react-form'
import { SelectField } from 'material-ui';

export default class CustomMaterialSelect extends React.Component {
  static propTypes = {
    name: React.PropTypes.string,
  };

  render() {
    const {field, children, label, ...rest} = this.props;
    return (
      <FormInput field={field}>
        {({ setValue, getValue, setTouched }) => {
          return (
            <SelectField
              {...rest}
              floatingLabelText={label}
              value={getValue()}
              onChange={(e, val, payload) => {
                setValue(payload)
              }}
            >
              {children}
            </SelectField>
          );
        }}
      </FormInput>
    );
  }
}
