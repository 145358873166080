
import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import cookie from 'react-cookie';
import Uploader from './';

import { API_URL } from '../../constants';

class UploadDemo extends React.Component {
  render() {
    return (
      <div>
        <Uploader
          onUpload={items => {
            let requests = [];
            items.forEach(item => {
              const data = new FormData();
              data.append('image', item);
              requests.push(axios.post(`${API_URL}/api/upload`, data, {
                headers: {
                  'content-type': 'multipart/form-data',
                  'Authorization': `Bearer ${cookie.load('token')}`
                }
              }));
            });
          }}
          onDrop={items => {
            console.log('onDrop\n', items);
          }}
          onClose={items => {
            console.log('onClose\n', items);
          }}
        />
      </div>
    );
  }
}

export default connect()(UploadDemo);
