import React from "react";
import { Tab } from "semantic-ui-react";
import DashboardTable from "./DashboardTable";
import DashboardPie from "./DashboardPie";
export default class MyDashboard extends React.Component {
  renderTabPanes = () => {
    const { approvedQuotes = [], deniedQuotes = [], completedQuotes = [], openQuotes = [] } = this.props;
    return [
      {
        menuItem: `My Open Quotes (${openQuotes.length})`,
        render: () => (
          <Tab.Pane>
            <DashboardTable
              title={"My Open Quotes"}
              data={openQuotes}
              color="orange"
              description="Quotes you have open with a client"
            />
          </Tab.Pane>
        )
      },
      {
        menuItem: `My Approved Quotes (${approvedQuotes.length})`,
        render: () => (
          <Tab.Pane>
            <DashboardTable
              title={"My Approved Quotes"}
              data={approvedQuotes}
              color="green"
              description="Quotes that have been approved by a client"
            />
          </Tab.Pane>
        )
      },
      {
        menuItem: `My Denied Quotes (${deniedQuotes.length})`,
        render: () => (
          <Tab.Pane>
            <DashboardTable
              title={"My Denied Quotes"}
              data={deniedQuotes}
              color="red"
              description="Quotes that have been denied by a client"
            />
          </Tab.Pane>
        )
      },
      {
        menuItem: `My Completed Quotes (${completedQuotes.length})`,
        render: () => (
          <Tab.Pane>
            <DashboardTable
              title={"My Completed Quotes"}
              data={completedQuotes}
              color="teal"
              description="Quotes that have been Completed"
            />
          </Tab.Pane>
        )
      }
    ];
  };

  render() {
    const { approvedQuotes = [], deniedQuotes = [], completedQuotes = [], openQuotes = [] } = this.props;

    return (
      <section className="dashboard-section">
        <h2>My Quotes Dashboard</h2>

        <div className="dashboard-charts-container">
          <div className="item report-item">
            <h3>Quotes by Approval Type</h3>
            <DashboardPie
              data={[
                approvedQuotes.length && {
                  angle: approvedQuotes.length,
                  label: "Approved",
                  color: "#17a538"
                },
                completedQuotes.length && {
                  angle: completedQuotes.length,
                  label: "Completed",
                  color: "#00B5AD"
                },
                openQuotes.length && {
                  angle: openQuotes.length,
                  label: "Open",
                  color: "#F2711C"
                },
                deniedQuotes.length && {
                  angle: deniedQuotes.length,
                  label: "Denied",
                  color: "#840000"
                }
              ].filter(o => o)}
            />
          </div>
        </div>

        <Tab panes={this.renderTabPanes()} />
      </section>
    );
  }
}
