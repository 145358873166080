import { push } from "react-router-redux";
import { createAction } from "redux-actions";
import { createAPIAction } from "redux-actions-api-addon";
import { Schemas } from "../schemas";

export const createQuoteMessage = createAPIAction(
  "QUOTE_MESSAGE",
  "POST",
  "/quote_messages",
  data => data,
  () => ({
    authenticated: true
    // onSuccess(dispatch, { response }) {
    //   dispatch(push("/dashboard/quotes"));
    // }
  })
);

export const updateQuoteMessage = createAPIAction(
  "QUOTE_MESSAGE",
  "PUT",
  (id, data) => `/quote_messages/${id}`,
  (id, data) => data,
  () => ({
    authenticated: true
    // onSuccess(dispatch, { response }) {
    //   dispatch(push("/dashboard/quotes"));
    // }
  })
);

export const logout = createAction("LOGOUT");
