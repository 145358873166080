import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router";
import { login, logout } from "../../actions/login";

import "./Login.css";

class LoginPage extends Component {
  componentWillUpdate(nextProps) {
    if (!this.props.isLoggedIn && nextProps.isLoggedIn) {
      this.props.router.push("/dashboard");
    }
  }

  render() {
    const { signIn, errorMessage } = this.props;

    return (
      <div className="login-container">
        <div className="login-left" />

        <div className="login-right">
          <div className="login-box">
            <h1>Welcome</h1>
            <p>
              Welcome to the OCLM Admin Console.<br />
              Please enter your credentials below to continue.{" "}
            </p>
            <div style={{ color: "red" }}>{errorMessage}</div>

            <input
              className="textInput"
              type="text"
              ref={v => {
                this.username = v;
              }}
              placeholder="Email"
            />
            <input
              className="textInput"
              type="password"
              ref={v => {
                this.password = v;
              }}
              placeholder="Password"
            />

            {/* <div>
              <Link to="/register">Create a new account</Link>
            </div> */}

            {/* <div>
              <Link to="/portal/login">OCLM Customer Login</Link>
            </div> */}

            <button
              className="btnLogin"
              onClick={() => {
                signIn(this.username.value, this.password.value);
              }}
            >
              Sign In
            </button>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { isLoggedIn, token, errorMessage } = state.auth;
  return {
    token,
    isLoggedIn,
    errorMessage
  };
}

function mapDispatchToProps(dispatch) {
  return {
    signIn(email, password) {
      return dispatch(login(email, password));
    },
    signOut() {
      return dispatch(logout());
    }
  };
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(LoginPage);
