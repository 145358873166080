import React from "react";
import PropTypes from "prop-types";
import { Button, Table } from "semantic-ui-react";
import { AWAITING_CLIENT_APPROVAL } from "../../constants";
import { getQuoteById } from "../../actions/quotes";
import { generateLinks, deleteShareLink } from "../../actions/quote-approvers";
import ManagerSearch from "../../components/ManagerSearch/ManagerSearch";
import { getApprovalStatus } from "../../utils/quotes";
import { copyToClipboard } from "../../utils/copy";

function createData(data, quoteId) {
  return {
    manager: {
      id: data.id,
      name: data.name,
      management: {
        company_name: data.company_name,
      },
    },
    manager_id: data.id,
    public_access_token: null,
    quote_id: quoteId,
  };
}

class GenerateShareLinks extends React.Component {
  static propTypes = {
    quote: PropTypes.object,
  };

  static defaultProps = {
    quote: {},
  };

  copyText = null;

  state = {
    managers: [],
  };

  componentDidMount() {
    const { quote } = this.props;
    const { additional_approvers = [], manager } = quote;

    this.setState({
      managers: additional_approvers,
    });
  }

  onManagerSelect = (e, data) => {
    const { quote } = this.props;
    const newData = createData(data, quote.id);

    this.setState({
      managers: [...this.state.managers, newData],
    });
  };

  componentDidUpdate(prevProps) {
    if (this.props.quote) {
      if (
        (this.props.quote && !prevProps.quote) ||
        this.props.quote.additional_approvers !== prevProps.quote.additional_approvers
      ) {
        this.setState({
          managers: this.props.quote.additional_approvers,
        });
      }
    }
  }

  onGenerate = () => {
    const { quote, dispatch } = this.props;

    const availableManagers = this.state.managers.filter((o) => !o.public_access_token).map((o) => o.manager_id);

    dispatch(
      generateLinks(quote.id, {
        managerIds: availableManagers,
      })
    ).then(() => {
      dispatch(getQuoteById(quote.id));
    });
  };

  onCopy = (item) => {
    copyToClipboard(item.shortlink, this.copyText);
  };

  onDeleteShareLink = (item) => {
    console.log("item", item);
    const { dispatch } = this.props;
    dispatch(deleteShareLink(item.id));
  };

  render() {
    const { managers } = this.state;

    const { quote } = this.props;

    const approvalStatus = getApprovalStatus(quote.approval_id, quote.approval_user_id, quote.manager_approved_on);

    return (
      <div style={{ marginTop: "3rem", marginBottom: "3rem" }}>
        <h3>Generate Share Links</h3>

        <ManagerSearch onManagerSelect={this.onManagerSelect} />

        <Table>
          <Table.Body>
            {managers.map((item) => {
              return (
                <Table.Row key={item.id}>
                  <Table.Cell>{item.manager.name}</Table.Cell>
                  <Table.Cell style={{ width: "200px" }}>
                    {item.shortlink && (
                      <div>
                        <input
                          contenteditable
                          type="text"
                          ref={(d) => {
                            this.copyText = d;
                          }}
                          style={{ background: "none", border: "none" }}
                          value={item.shortlink}
                        ></input>

                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <Button
                            color={"yellow"}
                            onClick={() => {
                              this.onCopy(item);
                            }}
                          >
                            Copy Link
                          </Button>

                          <Button
                            color={"red"}
                            onClick={() => {
                              this.onDeleteShareLink(item);
                            }}
                          >
                            Delete
                          </Button>
                        </div>
                      </div>
                    )}
                    {!item.shortlink && <p>No Link</p>}
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>

        <Button primary onClick={this.onGenerate}>
          Generate Links
        </Button>

        {approvalStatus.key !== AWAITING_CLIENT_APPROVAL && (
          <div style={{ marginTop: "1rem" }}>
            <p>
              Once links are generated, the Quote will be in <b>Submitted</b> Status
            </p>
          </div>
        )}
      </div>
    );
  }
}

export default GenerateShareLinks;
