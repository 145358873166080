import React from "react";
import { connect } from "react-redux";
import numeral from "numeral";

class QuoteTotal extends React.Component {
  static propTypes = {
    name: React.PropTypes.string
  };

  render() {
    const {
      quotes,
      total,
      approval_user_id,
      regional_managers
    } = this.props;

    return (
      <div className="quote-total">
        <b>Total: {numeral(total).format("$0,0.00")}</b>
      </div>
    );
  }
}

QuoteTotal.propTypes = {
  approval_user_id: React.PropTypes.number,
  quotes: React.PropTypes.array
};

QuoteTotal.defaultProps = {
  quotes: []
};

function mapStateToProps(state) {
  return {
    regional_managers: state.users.regional_managers
  };
}

// export default QuoteTotal;
export default connect(mapStateToProps)(QuoteTotal);
