import React, { Component } from "react";
import { connect } from "react-redux";
import { LOGIN_CHECK_MS } from "../../constants";
import { Button, Dimmer, Modal, Loader, Header } from "semantic-ui-react";
import { getLoggedInUser } from "../../actions/auth";
import "./Admin.css";

import AdminTop from "./AdminTop";

import Navigation from "../../components/Navigation";
import NavigationMobile from "../../components/NavigationMobile";

import SearchBar from "../Search/SearchBar";

class Admin extends Component {
  state = {
    showLoginExpiredModal: false,
    showMobileMenu: false
  };

  componentDidMount() {
    const { dispatch } = this.props;
    const interval = setInterval(() => {
      dispatch(getLoggedInUser()).catch(() => {
        this.setState({
          showLoginExpiredModal: true
        });
        clearInterval(interval);
      });
    }, LOGIN_CHECK_MS);
  }

  hideMenu = () => {
    this.setState({ showMobileMenu: false });
  };

  onHandleLoginClick() {
    window.location.href = `${process.env.REACT_APP_CONTRACTS_APP_URL}#/login?redirect_to=${window.location.href}`;
  }

  render() {
    const { user, isLoading } = this.props;
    const { showLoginExpiredModal } = this.state;
    return (
      <div className="admin-wrapper">
        {Boolean(isLoading) && (
          <Dimmer active inverted>
            <Loader indeterminate>Loading</Loader>
          </Dimmer>
        )}

        <Modal open={showLoginExpiredModal}>
          <Header icon="warning circle" content="Login Expired" />
          <Modal.Content>
            <p>Your login session has expired. Please login again in order to continue using the Portal</p>
          </Modal.Content>
          <Modal.Actions>
            <Button color="green" onClick={this.onHandleLoginClick}>
              Login
            </Button>
          </Modal.Actions>
        </Modal>

        <SearchBar className="portal-search-bar" />

        <AdminTop />

        <div
          className="ion-navicon-round mobile-burger-menu"
          onClick={() => {
            this.setState({ showMobileMenu: !this.state.showMobileMenu });
          }}
        />
        <NavigationMobile user={user} showMobileMenu={this.state.showMobileMenu} />

        <div className="admin-container">
          <Navigation user={user} />
          <div className="content content-container">{this.props.children}</div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    isLoading: state.app.isLoading
  };
}

export default connect(mapStateToProps)(Admin);
