import assign from "lodash/assign";
import merge from "lodash/merge";
import findIndex from "lodash/findIndex";
const initialEntityState = {
  quotes: {},
  quote_items: {},
  users: {},
};

// Updates an entity cache in response to any action with response.entities.
const entities = (state = initialEntityState, action) => {
  if (action.response && action.response.entities) {
    // console.log("state\n", JSON.stringify(state));
    // console.log("action.response.entities\n", JSON.stringify(action.response.entities));
    return merge({}, state, action.response.entities);
  }

  switch (action.type) {
    case "COMPLETE_QUOTE_PUT_SUCCESS": {
      const { id: quote_id } = action.response;
      return {
        ...state,
        quotes: {
          ...state.quotes,
          [quote_id]: {
            ...state.quotes[quote_id],
            ...action.response,
          },
        },
      };
    }
    case "DELETE_SHARE_LINK_DELETE_SUCCESS":
      const { quote_id, id } = action.response.data;

      const delIndex = findIndex(state.quotes[quote_id].additional_approvers, (item) => item === id);
      return {
        ...state,
        quotes: {
          ...state.quotes,
          [quote_id]: {
            ...state.quotes[quote_id],
            additional_approvers: [
              ...state.quotes[quote_id].additional_approvers.slice(0, delIndex),
              ...state.quotes[quote_id].additional_approvers.slice(delIndex + 1),
            ],
          },
        },
      };

    default:
      return state;
  }
};

export default entities;
