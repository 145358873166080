import React, { Component } from "react";
import { Link } from "react-router";
import { connect } from "react-redux";
import { getPurchaseOrder } from "../../actions/purchase_orders";

import { Default, Watson } from "../../components/QuoteTemplates";

class ViewPurchaseOrder extends Component {
  componentDidMount() {
    const { dispatch, params } = this.props;
    const { id } = params;
    dispatch(getPurchaseOrder(id));
  }

  onConvert = () => {
    const { getPurchaseOrder, params } = this.props;
    const { id } = params;
  };

  render() {
    const { template_type, params } = this.props;

    return (
      <div className="section-wrapper">
        <h1>View Purchase Order</h1>
        <section className="section section--quotes">
          <div className="content-panel">
            {template_type === 1 && <Default {...this.props} />}

            {template_type === 2 && <Watson {...this.props} />}

            {template_type === 3 && <Default {...this.props} />}
          </div>
          <div className="action-panel">
            <div className="action-panel">
              <Link to={`/dashboard/quotes/email/${params.id}`}>
                <button>Email Purchase Order</button>
              </Link>
              <Link to={`/dashboard/quotes/edit/${params.id}`}>
                <button>Edit Purchase Order</button>
              </Link>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

ViewPurchaseOrder.defaultProps = {
  customer: {}
};

function mapStateToProps(state, ownProps) {
  // get id from parameter
  const { id } = ownProps.params;
  // const { purchase_orders, assets: assetEntities, quote_items: quoteItemEntities } = state.entities;
  const { purchase_orders = {}, users = {} } = state.entities;

  const item = purchase_orders[id] || {};
  const user = users[item.user];

  const { purchase_order_items = [] } = item;

  // const { assets = [], quote_items = [] } = quote;
  const items = purchase_order_items.map(id => purchase_orders[id]);
  return {
    ...item,
    items,
    user
  };
}

export default connect(mapStateToProps)(ViewPurchaseOrder);
