import React from "react";
import { FormInput } from "react-form";
// import { TextField } from 'material-ui';
import { TextArea } from "semantic-ui-react";

export default class CustomText extends React.Component {
  state = {
    value: this.props.value || null,
  };

  render() {
    const { field, onChange, className, inputClassName, ...rest } = this.props;

    return (
      <FormInput field={field} className={className}>
        {({ setValue, getValue, setTouched }) => {
          return (
            <TextArea
              {...rest}
              onChange={(e, { value }) => {
                this.setState({ value });
                setValue(value);
                onChange(value);
              }}
            />
          );
        }}
      </FormInput>
    );
  }
}

CustomText.defaultProps = {
  onChange() {},
};
