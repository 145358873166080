import { createAPIAction } from "redux-actions-api-addon";
import { push } from "react-router-redux";

export const sendEmail = createAPIAction(
  "EMAIL",
  "POST",
  "/emails",
  data => data,
  () => ({
    authenticated: true,
    onSuccess(dispatch, action) {
      dispatch(push(`/dashboard/quotes/view/${action.response.quote_id}`));
    }
  })
);
