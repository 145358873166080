import { handleActions } from "redux-actions";
import keyBy from "lodash/keyBy";

const initialState = {
  regional_managers: [],
  oclmusers: [],
  managers: [],
};

export default handleActions(
  {
    REGIONAL_MANAGERS_SEARCH_GET_SUCCESS: (state, action) => {
      return {
        ...state,
        regional_managers: action.response,
      };
    },

    OCLM_USERS_GET_SUCCESS: (state, action) => {
      return {
        ...state,
        oclmusers: keyBy(action.response, "id"),
      };
    },

    MANAGERS_GET_SUCCESS: (state, action) => {
      return {
        ...state,
        managers: action.response,
      };
    },
  },
  initialState
);
