
import React, { Component } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { Link } from 'react-router';
import { Button, Form, Grid, Header, Message, Segment } from 'semantic-ui-react'
import { resetPassword } from '../../actions/auth';

class Register extends Component {

  state = {};

  onRegister = () => {
    const { location } = this.props;
    this.props.dispatch(resetPassword({
      email: get(location, 'query.email'),
      password: this.state.password,
      token: get(location, 'query.token'),
    }))
  }

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  render() {
    const { location } = this.props;
    return (
      <div style={{
        display: 'flex',
        flex: '1 1 auto',
        justifyContent: 'center',
        alignItems: 'center',
        }}>
        <Grid
          textAlign='center'
          style={{ height: '100%', maxWidth: '500px', width: '100%' }}
          verticalAlign='middle'
        >
          <Grid.Column>
            <Header as='h2' color='red' textAlign='center'>
              Create a new Account
            </Header>

              <Segment stacked>
                <Form.Input
                  fluid
                  icon='user'
                  iconPosition='left'
                  placeholder='E-mail address'
                  value={get(location, 'query.email', '')}
                  disabled
                />
                <Form.Input
                  fluid
                  icon='lock'
                  iconPosition='left'
                  placeholder='Password'
                  name="password"
                  type='password'
                  onChange={this.handleInputChange}
                />

                <Button
                  color='red'
                  fluid
                  size='large'
                  onClick={this.onRegister}
                >Register</Button>
              </Segment>
            
            <Message>
              Already Registered? <Link to="/login">Login</Link>
            </Message>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

export default connect()(Register);
