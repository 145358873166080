import React from 'react';
import { connect } from 'react-redux';
import { Button, Dimmer, Loader, Grid, Segment, Input, Form } from 'semantic-ui-react';
import { sendEmail } from '../../actions/emails';
import { getQuoteById } from '../../actions/quotes';

class EmailQuote extends React.Component {
  static propTypes = {
    name: React.PropTypes.string,
  };

  state = {
    email: '',
    isLoading: false,
  };

  componentDidMount() {
    this.props.dispatch(getQuoteById(this.props.params.id));
  }

  componentWillReceiveProps(nextProps) {
    const { customers = {} } = this.props;
    const { customers: nextCustomer } = nextProps;
    if (this.state.email !== nextCustomer.email) {
      this.setState({
        email: nextCustomer.email,
      });
    }
  }

  onSend = e => {
    e.preventDefault();
    const { customers, params } = this.props;
    const { id } = params;
    this.setState({
      isLoading: true,
    });
    this.props
      .dispatch(
        sendEmail({
          quote_id: id,
          to: this.state.email,
          subject: `OCLM Quote: #${id}`,
          text: 'Attached is your Quote',
          html: 'Attached is your Quote',
        }),
      )
      .then(() => {
        this.setState({ isLoading: false });
      });
  };

  onChange = e => {
    this.setState({ email: e.target.value });
  };

  render() {
    const { customers = {} } = this.props;
    const { email, isLoading } = this.state;

    return (
      <Grid padded>
        {isLoading && (
          <Dimmer active>
            <Loader>Emailing Quote</Loader>
          </Dimmer>
        )}

        <Grid.Row>
          <Grid.Column>
            <Segment>
              <h1>Email Quote</h1>

              <p>Add in a recient for this quote and it will be automatically delivered to them.</p>

              <p>You can enter multiple e-mail addresses. Separate each email by a comma.</p>

              <Form>
                <Form.Field>
                  <label>Email To</label>
                  <Input fluid placeholder="Email to..." onChange={this.onChange} value={email} />
                </Form.Field>
                {/* <Form.Field>
                    <Checkbox label='CC myself' />
                  </Form.Field> */}
                <Button primary type="submit" onClick={this.onSend}>
                  Email Quote
                </Button>
              </Form>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

EmailQuote.defaultProps = {
  customers: {},
};

function mapStateToProps(state, ownProps) {
  const { entities } = state;
  const quote = entities.quotes[ownProps.params.id];

  return {
    ...quote,
  };
}
export default connect(mapStateToProps)(EmailQuote);
