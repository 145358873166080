import { createAPIAction } from "redux-actions-api-addon";
import { push } from "react-router-redux";

export const resetPassword = createAPIAction(
  "RESET",
  "POST",
  ({ token }) => `/reset/${token}`,
  data => data,
  () => ({
    onSuccess(dispatch) {
      dispatch(push("/dashboard/customers"));
    }
  })
);

export const getLoggedInUser = createAPIAction(
  "LOGGED_IN_USER",
  "GET",
  () => `/token`,
  data => data,
  () => ({
    authenticated: true
    // onSuccess(dispatch) {
    //   dispatch(push('/dashboard/customers'))
    // }
  })
);

export const getPortalUserData = createAPIAction(
  "PORTAL_USER_DATA",
  "GET",
  () => `/portalUser`,
  null,
  () => ({
    authenticated: true
    // onSuccess(dispatch) {
    //   dispatch(push('/dashboard/customers'))
    // }
  })
);


export function handleLogin() {
  return function (dispatch) {
    return Promise.all([
      dispatch(getLoggedInUser()),
      dispatch(getPortalUserData())
    ])
  };
}
