import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import axios from "axios";
import cookie from "react-cookie";

import * as renderers from "./renderers";

import "./SearchPage.css";

class SearchPage extends React.Component {
  state = {
    loaded: false,
    results: [],
  };
  async componentDidMount() {
    const { location } = this.props;
    const { query } = location.query;

    const results = await this.getData(query);
    this.setState({
      loaded: true,
      results,
    });
  }

  getData = (input) => {
    return axios
      .get(`${process.env.REACT_APP_PORTAL_API_URL}/search?query=${input}`, {
        headers: {
          Authorization: `Bearer ${cookie.load("token")}`,
        },
      })
      .then((response) => response.data);
  };

  renderResult = (result, type) => {
    console.log("result", result);
    if (result.title === "user") {
      return renderers.renderUser(result);
    }

    if (result.title === "manager") {
      return renderers.renderManager(result);
    }

    if (result.title === "labor_rate") {
      return renderers.renderRate(result, "laborrate");
    }

    if (result.title === "pricing_group") {
      return renderers.renderRate(result, "pricinggroup");
    }

    if (result.title === "job") {
      return renderers.renderJob(result);
    }

    if (result.title === "management") {
      return renderers.renderManagement(result);
    }

    if (result.title === "contract") {
      return renderers.renderContract(result);
    }

    return renderers.renderQuotes(result);
  };

  render() {
    const { results, loaded } = this.state;
    const { location } = this.props;
    const { query } = location.query;

    return (
      <div>
        <h1>Search Results for: {query}</h1>

        {!loaded && <div>Loading...</div>}
        {!Boolean(results.length) && loaded && <div>No Results</div>}
        {Boolean(results.length) && results.map((result) => this.renderResult(result))}
      </div>
    );
  }
}

export default connect()(withRouter(SearchPage));
