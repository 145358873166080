import React, { Component, PropTypes } from "react";
import { CustomFormInput } from "../UI";
import { Button, Icon, Form, Modal, Header } from "semantic-ui-react";
import "./QuoteItems.css";

class AddItems extends Component {
  state = {
    isDeletingLineItem: false
  };

  onDeleteItem = e => {
    e.preventDefault();
    this.setState({
      isDeletingLineItem: true
    });
  };

  onDeleteConfirmed = () => {
    const { value, index } = this.props;
    this.props.onDeleteItem(value, index);
  };

  onClickUp = e => {
    e.preventDefault();
    const { value, index } = this.props;
    this.props.onMoveUp(value, index);
  };

  onClickDown = e => {
    e.preventDefault();
    const { value, index } = this.props;
    this.props.onMoveDown(value, index);
  };

  handleModalClose = () => this.setState({ isDeletingLineItem: false });

  render() {
    const { isDeletingLineItem } = this.state;
    const { field, value, setValue, index } = this.props;
    return (
      <section className="add-item-container">
        <Modal closeIcon open={isDeletingLineItem} onClose={this.handleModalClose}>
          <Header icon="archive" content="Delete Confirmation" />
          <Modal.Content>
            <p>Are you sure you want to delete this line item?</p>
          </Modal.Content>
          <Modal.Actions>
            <Button color="red" onClick={this.handleModalClose}>
              <Icon name="remove" /> No
            </Button>
            <Button color="green" onClick={this.onDeleteConfirmed}>
              <Icon name="checkmark" /> Yes
            </Button>
          </Modal.Actions>
        </Modal>
        <Form>
          <Form.Group widths="equal" className="form-group">
            <CustomFormInput
              fluid
              className="add-item location quote-title-column"
              value={value.location}
              field={[field, index, "location"]}
              fullWidth={true}
              placeholder="Location"
              onChange={() => {
                if (!value.isNew) {
                  setValue(["quotes", index, "isEdited"], true);
                }
              }}
            />

            <CustomFormInput
              fluid
              className="add-item description  quote-title-column"
              value={value.description}
              field={[field, index, "description"]}
              fullWidth={true}
              placeholder="Description"
              onChange={() => {
                if (!value.isNew) {
                  setValue(["quotes", index, "isEdited"], true);
                }
              }}
            />

            <CustomFormInput
              fluid
              className="add-item  quote-title-column"
              value={value.quantity}
              field={[field, index, "quantity"]}
              fullWidth={true}
              placeholder="Number of units"
              onChange={() => {
                if (!value.isNew) {
                  setValue(["quotes", index, "isEdited"], true);
                }
              }}
            />

            <CustomFormInput
              fluid
              className="add-item  quote-title-column"
              value={value.type}
              field={[field, index, "type"]}
              fullWidth={true}
              placeholder="Type ex: ea, lb, oz, ..."
              onChange={() => {
                if (!value.isNew) {
                  setValue(["quotes", index, "isEdited"], true);
                }
              }}
            />

            <CustomFormInput
              fluid
              className="add-item  quote-title-column"
              value={value.price}
              field={[field, index, "price"]}
              fullWidth={true}
              placeholder="Unit Price"
              onChange={() => {
                if (!value.isNew) {
                  setValue(["quotes", index, "isEdited"], true);
                }
              }}
            />

            <Button color="orange" icon onClick={this.onClickUp}>
              <Icon name="toggle up" />
            </Button>
            <Button color="yellow" icon onClick={this.onClickDown}>
              <Icon name="toggle down" />
            </Button>
            <Button color="red" onClick={this.onDeleteItem}>
              Delete
            </Button>
          </Form.Group>
        </Form>
      </section>
    );
  }
}

AddItems.propTypes = {
  onAddItem: PropTypes.func,
  onEditItem: PropTypes.func
};

AddItems.defaultProps = {
  onMoveUp() {},
  onMoveDown() {},
  onAddItem() {},
  onEditItem() {},
  onDeleteItem() {},
  value: {}
};

export default AddItems;
