import { handleActions } from "redux-actions";

const initialState = {};

export default handleActions(
  {
    PORTAL_USER_DATA_GET_SUCCESS: (state, action) => {
      return {
        ...action.response,
      };
    },
  },
  initialState
);
