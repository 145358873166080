import React from "react";
import { IMAGES_HOST } from "../../constants";
import "./QuoteImages.css";

export default function QuoteImages({ images = [] }) {
  if (!images.length) {
    return <div />;
  }

  return (
    <div className="quote-images-container">
      <h3>Quote Images</h3>

      <div className="preview-items">
        {images.map((img) => {
          return (
            <div className="quote-images-item preview-item">
              <img src={`${IMAGES_HOST}/${img.key}?w=250&h=250&fit=fill&fill-color=FFF`} role="presentation" />
              <div className="quote-images-caption">{img.caption}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
