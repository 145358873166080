import startCase from "lodash/startCase";
import toLower from "lodash/toLower";
import { DRAFT, APPROVAL_TYPES, FILTER_RESET } from "../../constants";

const RESET_KEY = {
  key: FILTER_RESET,
  value: FILTER_RESET,
  text: "Reset"
};

export const APPROVAL_OPTIONS = [
  RESET_KEY,
  {
    key: DRAFT,
    value: 0,
    text: "Draft"
  },
  ...Object.keys(APPROVAL_TYPES).map(key => {
    return {
      key: APPROVAL_TYPES[key],
      value: Number(key),
      text: startCase(toLower(APPROVAL_TYPES[key].replace(/(-|_)/g, " ")))
    };
  })
];

export const DATE_RANGE = [
  RESET_KEY,
  {
    key: "today",
    value: "today",
    text: "Today"
  },
  {
    key: "thisweek",
    value: "thisweek",
    text: "This Week"
  },
  {
    key: "thismonth",
    value: "thismonth",
    text: "This Month"
  },
  {
    key: "last30",
    value: "last30",
    text: "Last 30 Days"
  }
];

export function formatUsers(users = []) {
  if (!users || !users.map) {
    return [];
  }
  return [
    RESET_KEY,
    ...users.map(user => {
      return {
        key: user.id,
        value: user.id,
        text: user.fullName
      };
    })
  ];
}

export function formatManagers(users = []) {
  if (!users || !users.map) {
    return [];
  }

  return [
    RESET_KEY,
    ...users.map(user => {
      return {
        key: user.id,
        value: user.id,
        text: user.name
      };
    })
  ];
}
