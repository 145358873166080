import React, { Component } from "react";
import { Link } from "react-router";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { Breadcrumb, Button, Header, Modal, Icon } from "semantic-ui-react";
import { IMAGES_HOST } from "../../constants";
import { getQuoteById, convertQuoteToPO, copyQuote, submitForApproval } from "../../actions/quotes";
import "./ViewQuote.css";
import { Default, Watson, Ladera, OceanHills } from "../../components/QuoteTemplates";
import QuoteImages from "../../components/QuoteImages";
import { getApprovalStatus, formatQuoteNumber, getQuoteTotal } from "../../utils/quotes";
import { getRegionalManagers, getUserById } from "../../actions/users";

import { managerApproveQuote, approveQuote, denyQuote, crudUpdateQuote, completeQuote } from "../../actions/quotes";
import QuoteNotes from "../QuoteNotes";
import QuoteActions from "./QuoteActions";
import AdditionalApprovers from "../../components/AdditionalApprovers/AdditionalApprovers";
import QuoteStatus from "../../components/QuoteStatus";
import { denormalizeQuote, denormalizeUser } from "../../utils/denormalizers";

function resetCopyModal() {
  return {
    showCopyModal: false,
  };
}

class ViewQuote extends Component {
  state = {
    showConvertDialog: false,
    showCopyModal: false,
    showManualDenialModal: false,
    showManualApproveModal: false,
    showMarkAsCompletedModal: false,
    showReopenQuoteModal: false,
  };

  componentDidMount() {
    const { dispatch, params, quote } = this.props;
    const { id } = params;
    dispatch(getQuoteById(id));
    dispatch(getRegionalManagers());
  }

  componentDidUpdate(prevProps) {
    const { quote: prevQuote = {} } = prevProps;
    const { dispatch, quote = {} } = this.props;

    if (
      !isEmpty(quote) &&
      prevQuote &&
      prevQuote.approval_user_id !== quote.approval_user_id &&
      quote.approval_user_id !== null
    ) {
      // commenting out for now
      // dispatch(getUserById(quote.approval_user_id));
    }
  }

  onConvert = () => this.setState({ showConvertDialog: true });
  onConvertSuccess = () => {
    const { dispatch, params } = this.props;
    const { id } = params;
    dispatch(convertQuoteToPO(id));
  };

  onCopy = () => this.setState({ showCopyModal: true });
  onCopyConfirm = () => {
    const { dispatch, params } = this.props;
    const { id } = params;
    this.setState(
      {
        showCopyModal: false,
      },
      () => {
        dispatch(copyQuote(id));
      }
    );
  };

  onApproveQuote = () => {
    const quoteId = this.props.params.id;

    this.props
      .dispatch(
        managerApproveQuote({
          quote_id: quoteId,
        })
      )
      .then(() => {
        this.props.dispatch(getQuoteById(quoteId));
      });
  };

  onManualDenial = () => {
    this.setState({
      showManualDenialModal: true,
    });
  };

  onManualDenialConfirm = () => {
    const quoteId = this.props.params.id;
    this.setState({
      showManualDenialModal: false,
    });
    this.props.dispatch(denyQuote(quoteId)).then(() => {
      this.props.dispatch(getQuoteById(quoteId));
    });
  };

  onManualApproval = () => {
    this.setState({
      showManualApproveModal: true,
    });
  };

  onManualApprovalConfirm = () => {
    const quoteId = this.props.params.id;

    this.setState({
      showManualApproveModal: false,
    });
    this.props.dispatch(approveQuote(quoteId)).then(() => {
      this.props.dispatch(getQuoteById(quoteId));
    });
  };

  onMarkAsCompleted = () => {
    this.setState({
      showMarkAsCompletedModal: true,
    });
  };

  onMarkAsCompletedConfirm = () => {
    const { dispatch, params } = this.props;
    const { id } = params;
    this.setState({
      showMarkAsCompletedModal: false,
    });
    dispatch(
      completeQuote(id, {
        approval_id: 6,
      })
    ).then((resp) => {
      dispatch(getQuoteById(id));
    });
  };

  onQuoteReopen = () => {
    this.setState({
      showReopenQuoteModal: true,
    });
  };

  onQuoteReopenConfirm = () => {
    const { dispatch, params } = this.props;
    const { id } = params;
    this.setState({
      showReopenQuoteModal: false,
    });
    dispatch(
      crudUpdateQuote(id, {
        approval_id: null,
      })
    ).then((resp) => {
      dispatch(getQuoteById(id));
    });
  };

  onAddNote = () => {
    const { dispatch, params } = this.props;
    const { id } = params;
    dispatch(getQuoteById(id));
  };

  onSubmitApproval = (approval_user_id) => {
    const { dispatch, params } = this.props;
    const { id } = params;
    dispatch(submitForApproval(id, approval_user_id)).then((resp) => {
      dispatch(getQuoteById(id));
    });
  };

  render() {
    const { approval_user, dispatch, quote, regional_managers, logged_in_user_id } = this.props;

    if (!quote) {
      return <div />;
    }
    const {
      id: quoteId,
      approved_by_manager,
      additional_approvers,
      approval_id,
      created_at,
      approval_user_id,
      manager_approved_on,
      assets,
      template_type,
      messages = [],
      quote_items,
      signature_url,
    } = quote;

    const {
      showConvertDialog,
      showCopyModal,
      showManualApproveModal,
      showMarkAsCompletedModal,
      showReopenQuoteModal,
      showManualDenialModal,
    } = this.state;

    const approvalStatus = getApprovalStatus(approval_id, approval_user_id, manager_approved_on);

    const quoteTotal = getQuoteTotal(quote_items);

    return (
      <div className="section-wrapper">
        <Modal open={showMarkAsCompletedModal} basic>
          <Header icon="warning" content="Mark as Completed Confirmation" />
          <Modal.Content>
            <p>Are you sure you want to mark this quote as COMPLETED? </p>
            <p>Marking a quote as completed should be done when a quote job has been fully completed.</p>
          </Modal.Content>
          <Modal.Actions>
            <Button basic color="red" inverted onClick={() => this.setState({ showMarkAsCompletedModal: false })}>
              <Icon name="remove" /> No, close this window and take me back.
            </Button>
            <Button color="green" inverted onClick={this.onMarkAsCompletedConfirm}>
              <Icon name="checkmark" /> Yes, set this quote to be COMPLETED
            </Button>
          </Modal.Actions>
        </Modal>

        <Modal open={showReopenQuoteModal} basic>
          <Header icon="warning" content="Reopen Quote Confirmation" />
          <Modal.Content>
            <p>Are you sure you want to reset this Quote back to a DRAFT? </p>
            <p>
              Reopening a quote will put it back into DRAFT state. This will remove the quote from the clients view as
              well. Please be careful when doing so, as you can have the power to reopen quote that has already been
              approved by a client.
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button basic color="red" inverted onClick={() => this.setState({ showReopenQuoteModal: false })}>
              <Icon name="remove" /> No, close this window and take me back.
            </Button>
            <Button color="green" inverted onClick={this.onQuoteReopenConfirm}>
              <Icon name="checkmark" /> Yes, reset this quote back to a DRAFT
            </Button>
          </Modal.Actions>
        </Modal>

        <Modal open={showManualApproveModal} basic>
          <Header icon="warning" content="Manual Approval Confirmatoin" />
          <Modal.Content>
            <p>Are you sure you want to manually approve this quote? </p>
            <p>
              Customers generally should approve the Quote in their client portal. Proceed if you have received
              documented approval of the Quote Acceptance.
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button basic color="red" inverted onClick={() => this.setState({ showManualApproveModal: false })}>
              <Icon name="remove" /> No, close this window and take me back.
            </Button>
            <Button color="green" inverted onClick={this.onManualApprovalConfirm}>
              <Icon name="checkmark" /> Yes, I have received documented Approval
            </Button>
          </Modal.Actions>
        </Modal>

        <Modal open={showManualDenialModal} basic>
          <Header icon="warning" content="Manual Denial Confirmatoin" />
          <Modal.Content>
            <p>Are you sure you want to manually deny this quote? </p>
            <p>
              Customers generally should approve or deny the Quote in their client portal. Proceed if you have received
              documented denial of the Quote.
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button basic color="red" inverted onClick={() => this.setState({ showManualApproveModal: false })}>
              <Icon name="remove" /> No, close this window and take me back.
            </Button>
            <Button color="green" onClick={this.onManualDenialConfirm}>
              <Icon name="checkmark" /> Yes, I have received documented Denial
            </Button>
          </Modal.Actions>
        </Modal>

        <Modal open={showConvertDialog} basic>
          <Header icon="warning" content="Purchase Order Conversion" />
          <Modal.Content>
            <p>Are you sure you want to send this quote to Purchasing?</p>
          </Modal.Content>
          <Modal.Actions>
            <Button basic color="red" inverted onClick={() => this.setState({ showConvertDialog: false })}>
              <Icon name="remove" /> No
            </Button>
            <Button color="green" inverted onClick={this.onConvertSuccess}>
              <Icon name="checkmark" /> Yes
            </Button>
          </Modal.Actions>
        </Modal>

        <Modal open={showCopyModal} basic>
          <Header icon="refresh" content="Copy Quote" />
          <Modal.Content>
            <p>Are you sure you want to copy this quote?</p>
          </Modal.Content>
          <Modal.Actions>
            <Button basic color="red" inverted onClick={() => this.setState(resetCopyModal)}>
              <Icon name="remove" /> No
            </Button>
            <Button color="green" inverted onClick={this.onCopyConfirm}>
              <Icon name="checkmark" /> Yes, Copy this Quote
            </Button>
          </Modal.Actions>
        </Modal>

        <div className="quote-top-section">
          <Breadcrumb className="quote-breadcrumbs">
            <Breadcrumb.Section link>Home</Breadcrumb.Section>
            <Breadcrumb.Divider icon="right angle" />
            <Breadcrumb.Section link>
              <Link to="/dashboard/quotes">Quotes</Link>
            </Breadcrumb.Section>
            <Breadcrumb.Divider icon="right angle" />
            <Breadcrumb.Section active>Quote: {formatQuoteNumber(quoteId, created_at)}</Breadcrumb.Section>
          </Breadcrumb>
        </div>

        <h1>View Quote</h1>
        <QuoteStatus quote={quote} approval_user={approval_user} />

        <section className="section section--quotes">
          <div className="action-panel">
            <QuoteActions
              id={quoteId}
              approvalStatus={approvalStatus}
              logged_in_user_id={logged_in_user_id}
              approval_user_id={approval_user_id}
              onCopy={this.onCopy}
              onApproveQuote={this.onApproveQuote}
              onManualApproval={this.onManualApproval}
              onManualDenial={this.onManualDenial}
              onMarkAsCompleted={this.onMarkAsCompleted}
              onQuoteReopen={this.onQuoteReopen}
              regional_managers={regional_managers}
              quote_total={quoteTotal}
              onSubmitApproval={this.onSubmitApproval}
            />
            <hr />
          </div>

          <div className="content-panel">
            {template_type === 1 && <Default {...quote} />}

            {template_type === 2 && <Watson {...quote} />}

            {template_type === 3 && <Ladera {...quote} />}

            {template_type === 4 && <OceanHills {...quote} />}

            {signature_url && (
              <div>
                <h3>Electronic Signature</h3>
                <img src={`${IMAGES_HOST}/${signature_url}?w=200`} role="presentation" />
              </div>
            )}

            <QuoteImages images={assets} />
          </div>
        </section>

        <AdditionalApprovers
          quote={quote}
          getQuoteById={getQuoteById}
          dispatch={dispatch}
          managers={additional_approvers}
        />

        <QuoteNotes quote_id={quoteId} messages={messages} onAddNote={this.onAddNote} />
      </div>
    );
  }
}

ViewQuote.defaultProps = {
  customer: {},
};

function mapStateToProps(state, ownProps) {
  // get id from parameter
  const { id } = ownProps.params;
  const quote = denormalizeQuote(state, id);

  const regional_managers = state.users.regional_managers;
  const logged_in_user_id = state.user.id;
  if (!quote) {
    return {};
  }

  return {
    quote,
    regional_managers,
    logged_in_user_id,
    approval_user: denormalizeUser(state, quote.approval_user_id),
  };
}

export default connect(mapStateToProps)(ViewQuote);
