
import { handleActions } from 'redux-actions';
import { assign, union, indexOf } from 'lodash';

const initialState = {
  purchaseOrderIds: [],
  pagination: {}
};

export default handleActions({
  'PURCHASE_ORDERS_GET_SUCCESS': (state, { response }) => {
    return Object.assign({}, {
      purchaseOrderIds: union(state.purchaseOrderIds, response.result.items),
      pagination: response.result.pagination,
    });
  },

  'PURCHASEORDERBYID_GET_SUCCESS': (state, { response }) => {
    return Object.assign({}, {
      purchaseOrderIds: union(state.purchaseOrderIds, [response.result]),
    });
  },

  'PURCHASE_ORDERS_DELETE_SUCCESS': (state, { response }) => {
    const index = indexOf(state.purchaseOrderIds, response.id);
    const newIds = index > -1 ? [
      ...state.purchaseOrderIds.slice(0, index),
      ...state.purchaseOrderIds.slice(index + 1),
    ] : state;
    return assign({}, state, {
      purchaseOrderIds: newIds,
    });
  },
}, initialState);
