import React from "react";
// import { Input } from "semantic-ui-react";
import { Grid, Image, Input } from "semantic-ui-react";

export default function Previews({ items, onClose, onAddCaption }) {
  return (
    <div className="uploader-preview-container">
      <h3>Previews</h3>
      <Grid divided>
        {items.map((obj, idx) => {
          const src = obj.path || obj.preview;
          return (
            <Grid.Row key={idx + obj.name}>
              <Grid.Column width={3}>
                <div
                  className="uploader-preview-close"
                  onClick={() => {
                    onClose(obj, idx);
                  }}
                />
                <img src={src} role="presentation" className="uploader-preview-image" />
              </Grid.Column>
              <Grid.Column width={13}>
                Caption (<span className="help-text">Max 100 chars</span>)
                <Input
                  placeholder="Add a Caption..."
                  defaultValue={obj.caption || undefined}
                  fluid
                  onChange={(e, data) => {
                    onAddCaption(e, data, idx);
                  }}
                  maxLength={100}
                />
              </Grid.Column>
            </Grid.Row>
          );
        })}
      </Grid>
    </div>
  );
}
