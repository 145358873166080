import { handleActions } from "redux-actions";
import { assign, union, indexOf } from "lodash";

const initialState = {
  contractIds: []
};

export default handleActions(
  {
    PORTAL_CONTRACTS_GET_SUCCESS: (state, { response }) => {
      return Object.assign(
        {},
        {
          contractIds: union(response.result.items, state.quoteIds)
          // pagination: response.result.pagination,
        }
      );
    }
    //
    // 'QUOTEBYID_GET_SUCCESS': (state, { response }) => {
    //   return Object.assign({}, {
    //     quoteIds: union(state.quoteIds, [response.result]),
    //   });
    // },
    //
    // 'QUOTE_DELETE_SUCCESS': (state, { response }) => {
    //   const index = indexOf(state.quoteIds, response.id);
    //   const newIds = index > -1 ? [
    //     ...state.quoteIds.slice(0, index),
    //     ...state.quoteIds.slice(index + 1),
    //   ] : state;
    //   return assign({}, state, {
    //     quoteIds: newIds,
    //   });
    // },
  },
  initialState
);
