import React from "react";
import CustomerSearch from "./CustomerSearch";
import { CustomText } from "../UI";

import "./Customers.css";

export default function Create({ value, onCancel }) {
  return (
    <section className="quote-section new-customer-container">
      <div className="new-customer-content">
        <div className="primary">
          <h3>Company Information</h3>

          <CustomerSearch
            type="management"
            field="company_id"
            searchField="company_name"
            className="company-search"
            placeholder="Search Existing Management Companies"
          />
        </div>

        <div className="secondary" style={{ paddingLeft: "1rem" }}>
          <h3>Customer Information</h3>
          <div className="new-customer-content">
            <div className="primary">
              <CustomText
                value={value.name}
                field={["customer", "name"]}
                label="Name"
                placeholder="Add a First Name"
                fluid
              />

              <CustomText
                value={value.email}
                field={["customer", "email"]}
                label="Email Address"
                placeholder="Add an Email"
                fluid
              />

              <CustomText
                value={value.email}
                field={["customer", "phone"]}
                label="Phone Number"
                placeholder="Add a Phone Number"
                fluid
              />
            </div>
          </div>
        </div>
      </div>

      <button onClick={onCancel}>Cancel</button>
    </section>
  );
}

Create.defaultProps = {
  value: {}
};
