import React from "react";
import get from "lodash/get";

import { FlexibleWidthXYPlot, XAxis, YAxis, HorizontalBarSeries, Hint } from "react-vis";

export default class QuotePipeline extends React.Component {
  state = {
    useCanvas: false
  };

  _rememberValue = value => {
    console.log("value", value);
    this.setState({ value });
  };

  _forgetValue = () => {
    this.setState({
      value: null
    });
  };

  render() {
    const { value } = this.state;
    const { counts } = this.props;

    return (
      <div style={{ position: "relative", width: "700px", fontSize: 10 }}>
        <h3>Quote Status Breakdown</h3>
        <FlexibleWidthXYPlot height={300} yType="ordinal" margin={{ left: 140 }}>
          <XAxis />
          <YAxis left={0} />
          <HorizontalBarSeries
            colorType="literal"
            onValueMouseOver={this._rememberValue}
            onValueMouseOut={this._forgetValue}
            data={[
              {
                color: "#00B5AD",
                y: `Completed (${get(counts, "completed.counts.current.year.count", 0)})`,
                x: get(counts, "completed.counts.current.year.count", 0)
              },
              {
                color: "#840000",
                y: `Denied (${get(counts, "denied.counts.current.year.count", 0)})`,
                x: get(counts, "denied.counts.current.year.count", 0)
              },
              {
                color: "#17a538",
                y: `Approved (${get(counts, "approved.counts.current.year.count", 0)})`,
                x: get(counts, "approved.counts.current.year.count", 0)
              },
              {
                color: "#F2711C",
                y: `Open (${get(counts, "open.counts.current.year.count", 0)})`,
                x: get(counts, "open.counts.current.year.count", 0)
              }
            ]}
          />
          {value ? (
            <Hint horizontalAlign={Hint.ALIGN.RIGHT} verticalAlign={Hint.ALIGN.BOTTOM} value={value}>
              <div
                style={{
                  backgroundColor: "white",
                  padding: "5px",
                  borderRadius: "4px",
                  borderWidth: 1,
                  borderColor: "gray",
                  borderStyle: "solid"
                }}
              >
                <h3>{value.x} Quote(s)</h3>
              </div>
            </Hint>
          ) : null}
        </FlexibleWidthXYPlot>
      </div>
    );
  }
}
