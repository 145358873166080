import { createAPIAction } from "redux-actions-api-addon";
import { Schemas } from "../schemas";

export const addAdditionalApprovers = createAPIAction(
  "ADD_APPROVERS",
  "POST",
  quote_id => `/quotes/add-approvers/${quote_id}`,
  (quote_id, additional_managers) => ({
    quote_id,
    additional_managers
  }),
  () => ({
    authenticated: true
  })
);

export const generateLinks = createAPIAction(
  "GENERATE_PUBLIC_LINK",
  "POST",
  id => `/quote-approvers/${id}/generate-links`,
  (id, body) => body,
  () => ({
    authenticated: true
    // schema: Schemas.ADDITIONAL_APPROVERS
  })
);

export const deleteShareLink = createAPIAction(
  "DELETE_SHARE_LINK",
  "DELETE",
  id => `/quote-approvers/${id}`,
  null,
  () => ({
    authenticated: true
    // schema: Schemas.ADDITIONAL_APPROVERS
  })
);
