import React from "react";
import { Input, Button, Form } from "semantic-ui-react";

import "./SearchBar.css";

export default class SearchBar extends React.Component {
  state = {
    text: ""
  };

  static defaultProps = {
    className: ""
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { text } = this.state;
    if (text.trim() === "") {
      alert("You need to enter some text to search");
      return;
    }

    window.location.href = `/dashboard/search?query=${text}`;
  };

  render() {
    return (
      <div className={`${this.props.className} SearchBar--container`}>
        <Form onSubmit={this.handleSubmit} className="SearchBar--form">
          <div className="SearchBar--text">
            <Input
              fluid
              onChange={this.handleChange}
              name="text"
              value={this.state.text}
              placeholder="Enter a Quote, Job Name, Description, etc...."
            />
          </div>

          <div className="SearchBar--button">
            <Button primary type="submit">
              Search
            </Button>
          </div>
        </Form>
      </div>
    );
  }
}
