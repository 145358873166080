import React from "react";
import { FormInput } from "react-form";
// import { TextField } from 'material-ui';
import { Input, Form } from "semantic-ui-react";

export default class CustomFormInput extends React.Component {
  state = {
    value: this.props.value || null
  };

  render() {
    const { field, onChange, className, inputClassName, ...rest } = this.props;

    const value =
      this.state.value || this.state.value === ""
        ? this.state.value
        : this.props.value;

    return (
      <FormInput field={field} className={className}>
        {({ setValue, getValue, setTouched }) => {
          return (
            <Form.Input
              {...rest}
              onChange={(e, { value }) => {
                this.setState({ value });
                setValue(value);
                onChange(value);
              }}
            />
          );
        }}
      </FormInput>
    );
  }
}

CustomFormInput.defaultProps = {
  onChange() {}
};
