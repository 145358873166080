import React from "react";
import { Link } from "react-router";
import { Button, Popup, Dropdown, Modal, Icon } from "semantic-ui-react";
import { withRouter } from "react-router";

import RegionalManagerSelect from "../../components/RegionalManagerSelect/RegionalManagerSelect";

import "./QuoteActions.css";

import {
  APPROVED,
  DENIED,
  AWAITING_CLIENT_APPROVAL,
  AWAITING_OCLM_APPROVAL,
  IN_PROGRESS,
  QUOTE_COMPLETED,
  QUOTE_PRICE_NEEDS_APPROVAL,
  SENT_TO_CLIENT,
} from "../../constants";

class QuoteActions extends React.Component {
  state = {
    approvalModalOpen: false,
    approval_error: "",
    approval_id: null,
  };

  onOpenApprovModal = () => {
    this.setState({
      approvalModalOpen: true,
    });
  };

  onCloseApprovalModal = () => {
    this.setState({
      approvalModalOpen: false,
    });
  };

  onRegionalManagerSelect = (event, data) => {
    const { value } = data;
    this.setState({
      approval_error: "",
      approval_id: value,
    });
  };

  onSubmitApproval = () => {
    const { onSubmitApproval } = this.props;
    const { approval_id } = this.state;

    if (!approval_id) {
      this.setState({
        approval_error: "You need to select an approval manager",
      });
      return;
    }

    onSubmitApproval(approval_id);

    // looks good, reset
    this.setState({
      approval_error: "",
      approvalModalOpen: false,
    });
  };

  render() {
    const {
      id,
      approvalStatus,
      logged_in_user_id,
      approval_user_id,
      onCopy,
      onApproveQuote,
      onManualApproval,
      onMarkAsCompleted,
      onManualDenial,
      onQuoteReopen,
      router,
      regional_managers,
      quote_total,
    } = this.props;

    const { approvalModalOpen, approval_error } = this.state;
    return (
      <div className="quote-actions-container">
        {(approvalStatus.key === AWAITING_OCLM_APPROVAL ||
          approvalStatus.key === AWAITING_CLIENT_APPROVAL ||
          approvalStatus.key === SENT_TO_CLIENT ||
          approvalStatus.key === IN_PROGRESS) && (
          <Link className="item" to={`/dashboard/quotes/edit/${id}`}>
            <Button color="red">Edit Quote</Button>
          </Link>
        )}

        <div className="actions-dropdown-container">
          <Dropdown text="Actions" floating labeled button className="icon actions-dropdown">
            <Dropdown.Menu>
              <Dropdown.Header icon="tags" content="Quote Actions" />
              <Dropdown.Divider />
              {/* {approvalStatus.key !== AWAITING_OCLM_APPROVAL && (
                <Dropdown.Item
                  label={{ color: "red", empty: true, circular: true }}
                  text="Email Quote"
                  onClick={() => router.push(`/dashboard/quotes/email/${id}`)}
                />
              )} */}
              {!Boolean(approvalStatus.key === IN_PROGRESS && quote_total >= QUOTE_PRICE_NEEDS_APPROVAL) &&
                approvalStatus.key !== AWAITING_OCLM_APPROVAL && (
                  <Dropdown.Item
                    label={{ color: "red", empty: true, circular: true }}
                    text="Download PDF"
                    onClick={() => {
                      window.location = `${process.env.REACT_APP_API_URL}/api/quotes/download/${id}`;
                      return;
                    }}
                  />
                )}
              <Dropdown.Item
                label={{ color: "orange", empty: true, circular: true }}
                text="Copy Quote"
                onClick={onCopy}
              />

              {/* Used when a user needs to manually approve (ie: mailed in)*/}
              {Boolean(
                !Boolean(approvalStatus.key === IN_PROGRESS && quote_total >= QUOTE_PRICE_NEEDS_APPROVAL) &&
                  approvalStatus.key !== AWAITING_OCLM_APPROVAL &&
                  approvalStatus.key !== APPROVED &&
                  approvalStatus.key !== DENIED &&
                  approvalStatus.key !== QUOTE_COMPLETED
              ) && (
                <Dropdown.Item
                  label={{ color: "green", empty: true, circular: true }}
                  text="Manually Approve"
                  onClick={onManualApproval}
                />
              )}

              {/* Used when a user needs to manually deny a quote */}
              {Boolean(
                !Boolean(approvalStatus.key === IN_PROGRESS && quote_total >= QUOTE_PRICE_NEEDS_APPROVAL) &&
                  approvalStatus.key !== AWAITING_OCLM_APPROVAL &&
                  approvalStatus.key !== APPROVED &&
                  approvalStatus.key !== QUOTE_COMPLETED
              ) && (
                <Dropdown.Item
                  label={{ color: "red", empty: true, circular: true }}
                  text="Manually Deny"
                  onClick={onManualDenial}
                />
              )}

              {/* If a quote is approved, denied, or completed, let it be reopened */}
              {Boolean(
                approvalStatus.key === APPROVED ||
                  approvalStatus.key === DENIED ||
                  approvalStatus.key === QUOTE_COMPLETED
              ) && (
                <Dropdown.Item
                  label={{ color: "purple", empty: true, circular: true }}
                  text="Reopen Quote"
                  onClick={onQuoteReopen}
                />
              )}

              {/* Used to mark a quote as Completed */}
              {Boolean(approvalStatus.key !== QUOTE_COMPLETED) && (
                <Dropdown.Item
                  label={{ color: "blue", empty: true, circular: true }}
                  text="Mark as Completed"
                  onClick={onMarkAsCompleted}
                />
              )}

              {Boolean(approvalStatus.key !== IN_PROGRESS) && (
                <Dropdown.Item
                  label={{ color: "brown", empty: true, circular: true }}
                  text="Add Additional Approvers"
                  onClick={() => router.push(`/dashboard/quotes/add-approvers/${id}`)}
                />
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>

        {/* Used when a manager needs to approve a quote over 2k */}
        {approvalStatus.key === AWAITING_OCLM_APPROVAL && logged_in_user_id === approval_user_id && (
          <div className="item submit-quote">
            <Popup
              trigger={
                <Button className="item" onClick={onApproveQuote} color="green">
                  Approve Quote over 2k
                </Button>
              }
              content="If seen, you are a manager who can use this button to approve quotes that are over $2,000. "
              on="hover"
            />
          </div>
        )}

        {Boolean(approvalStatus.key === IN_PROGRESS && quote_total >= QUOTE_PRICE_NEEDS_APPROVAL) && (
          <Modal
            trigger={
              <Button className="item submit-quote" color="yellow" onClick={this.onOpenApprovModal}>
                Submit to Manager for Approval
              </Button>
            }
            open={approvalModalOpen}
            onClose={this.onCloseApprovalModal}
            size="small"
          >
            <Modal.Header>Select a Regional Manager for Approval</Modal.Header>
            <Modal.Content>
              <p>
                Quotes over $2,000 require OCLM Manager Approval before being sent to a client.
                <br />
                Please select an approval manager below approve your quote.
              </p>
              <RegionalManagerSelect
                logged_in_user_id={logged_in_user_id}
                regional_managers={regional_managers}
                onChange={this.onRegionalManagerSelect}
              />
              <span style={{ color: "red" }}>{approval_error}</span>
            </Modal.Content>
            <Modal.Actions>
              <Button negative onClick={this.onCloseApprovalModal}>
                Cancel
              </Button>
              <Button
                positive
                icon="checkmark"
                labelPosition="right"
                content="Send for Approval"
                onClick={this.onSubmitApproval}
              />
            </Modal.Actions>
          </Modal>
        )}

        {Boolean(
          !(approvalStatus.key === IN_PROGRESS && quote_total >= QUOTE_PRICE_NEEDS_APPROVAL) &&
            approvalStatus.key !== AWAITING_CLIENT_APPROVAL &&
            approvalStatus.key !== AWAITING_OCLM_APPROVAL &&
            approvalStatus.key !== APPROVED &&
            approvalStatus.key !== DENIED &&
            approvalStatus.key !== QUOTE_COMPLETED
        ) && (
          <Link className="item submit-quote" to={`/dashboard/quotes/submit/${id}`}>
            <Popup
              trigger={<Button color="blue">Submit Quote</Button>}
              content="Finalizes and Submits a quote to the Client, where they can accept via the Customer Portal."
              on="hover"
            />
          </Link>
        )}
      </div>
    );
  }
}

QuoteActions.defaultProps = {
  onMarkAsCompleted() {},
  onQuoteReopen() {},
};

export default withRouter(QuoteActions);
