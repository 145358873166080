import { createAction } from "redux-actions";
import { createAPIAction } from "redux-actions-api-addon";
import { push } from "react-router-redux";

export const login = createAPIAction(
  "LOGIN",
  "POST",
  "/authenticate",
  // "https://oclm-api.now.sh/authenticate",
  (username = "", password = "") => ({
    username,
    password
  }),
  () => ({
    // external: true
  })
);

export const logout = createAction("LOGOUT");
