import cookie from "react-cookie";

export default store => next => action => {
  switch (action.type) {
    case "LOGIN_POST_SUCCESS":
      if (action.response.error) {
        cookie.remove("token");
      } else {
        const token = action.response.token;
        if (token) {
          console.log("save cookie", token);
          cookie.save("token", token, {
            path: "/"
          });
        }
      }
      break;
    case "LOGOUT":
      cookie.remove("token");
      break;
    default:
      break;
  }
  next(action);
};
