import { logout } from "../actions/login";
import { store } from "../store";
import cookie from "react-cookie";
import { handleLogin } from "../actions/auth";

export function checkLogin(nextState, replace) {
  const state = store.getState();
  const { isLoggedIn } = state.auth;

  if (isLoggedIn === true) {
    replace("/dashboard");
  }
}

export async function checkAuth(nextState, replace, cb) {
  const state = store.getState();
  const { isLoggedIn } = state.auth;

  if (isLoggedIn) {
    store
      .dispatch(handleLogin())
      .then(() => {
        cb();
      })
      .catch(err => {
        window.location.href = `${process.env.REACT_APP_CONTRACTS_APP_URL}#/login?redirect_to=${window.location.href}`;
        replace("/redirect");
      });
  }

  // if not logged in, go to the portal login page
  if (!isLoggedIn) {
    window.location.href = `${process.env.REACT_APP_CONTRACTS_APP_URL}#/login?redirect_to=${window.location.href}`;
    replace("/redirect");
  }
}

export function loginOrDashboard(nextState, replace) {
  const state = store.getState();
  const { isLoggedIn } = state.auth;

  if (isLoggedIn === true) {
    replace("/dashboard");
  } else {
    window.location.href = `${process.env.REACT_APP_CONTRACTS_APP_URL}`;
  }
}

export function doLogout(nextState, replace) {
  cookie.remove("token");
  store.dispatch(logout());
  replace("/login");
}
