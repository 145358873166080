import React from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Link } from "react-router";
import findIndex from "lodash/findIndex";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { getQuoteById } from "../../actions/quotes";
import { addAdditionalApprovers } from "../../actions/quote-approvers";
import { Button, Breadcrumb, Table, Form } from "semantic-ui-react";
import ManagerSearch from "../../components/ManagerSearch/ManagerSearch";
import { denormalizeQuote } from "../../utils/denormalizers";
import { formatQuoteNumber } from "../../utils/quotes";

import cookie from "react-cookie";
const API_ROOT = `${process.env.REACT_APP_API_URL}/api`;

class AddQuoteApprovers extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    quote: PropTypes.object
  };

  state = {
    loaded: false,
    managers: []
    // managers: this.props.quote.additional_approvers.map(o => {
    //   return {
    //     id: o.manager.id,
    //     name: o.manager.name,
    //     company_name: o.manager.management.company_name
    //   };
    // })
  };

  async componentDidMount() {
    const { dispatch, params } = this.props;
    const { id } = params;
    dispatch(getQuoteById(id));
  }

  componentDidUpdate(prevProps) {
    const { loaded } = this.state;
    const { quote } = this.props;
    const { additional_approvers } = quote;

    if (loaded === false && additional_approvers) {
      this.setState({
        loaded: true,
        managers: additional_approvers.map(o => {
          return {
            id: o.manager.id,
            name: o.manager.name,
            company_name: o.manager.management.company_name
          };
        })
      });
    }
  }

  onHandleManagerSelect = (e, data) => {
    this.setState({
      managers: [
        ...this.state.managers,
        {
          id: data.id,
          name: data.name,
          company_name: data.company_name
        }
      ]
    });
  };

  onManagerRemove = data => {
    const index = findIndex(this.state.managers, ["id", data.id]);
    this.setState({
      managers: [...this.state.managers.slice(0, index), ...this.state.managers.slice(index + 1)]
    });
  };

  onSubmit = () => {
    const { dispatch, params, router } = this.props;
    const { id } = params;
    const { managers } = this.state;
    const manager_ids = managers.map(o => o.id);

    dispatch(addAdditionalApprovers(id, manager_ids)).then(() => {
      router.push(`/dashboard/quotes/view/${id}`);
    });
  };

  render() {
    const { portal_manager_id, quote } = this.props;
    const { managers } = this.state;

    console.log("managers", managers);

    if (!quote) {
      return <div />;
    }

    return (
      <div className="section-wrapper">
        <div className="quote-top-section">
          <Breadcrumb className="quote-breadcrumbs">
            <Breadcrumb.Section link>Home</Breadcrumb.Section>
            <Breadcrumb.Divider icon="right angle" />
            <Breadcrumb.Section link>
              <Link to="/dashboard/quotes">Quotes</Link>
            </Breadcrumb.Section>
            <Breadcrumb.Divider icon="right angle" />
            <Breadcrumb.Section active>
              {quote.id && (
                <Link to={`/dashboard/quotes/view/${quote.id}`}>
                  Quote: {formatQuoteNumber(quote.id, quote.created_at)}
                </Link>
              )}
            </Breadcrumb.Section>
            <Breadcrumb.Divider icon="right angle" />
            <Breadcrumb.Section active>Add Additional Approvers</Breadcrumb.Section>
          </Breadcrumb>
        </div>

        <h1>Add Additional Approvers</h1>
        <Form>
          <ManagerSearch
            title="Add Additional Approvers"
            onManagerSelect={this.onHandleManagerSelect}
            assigned_to={portal_manager_id}
          />

          {Boolean(managers.length) && (
            <div style={{ marginTop: "2rem" }}>
              <p>The following users will have the ability to Approve or Deny the quote</p>
              <Table>
                <Table.Header>
                  <Table.HeaderCell>Manager Name</Table.HeaderCell>
                  <Table.HeaderCell>Management Company</Table.HeaderCell>
                  <Table.HeaderCell>Action</Table.HeaderCell>
                </Table.Header>
                {Boolean(managers.length) &&
                  managers.map(manager => (
                    <Table.Row columns={2}>
                      <Table.Cell>{manager.name}</Table.Cell>
                      <Table.Cell>{manager.company_name}</Table.Cell>
                      <Table.Cell>
                        <span
                          className="remove"
                          onClick={() => {
                            this.onManagerRemove(manager);
                          }}
                        >
                          Remove
                        </span>
                      </Table.Cell>
                    </Table.Row>
                  ))}
              </Table>
            </div>
          )}
          {!Boolean(managers.length) && <p>There are currently no additional managers selected for approval</p>}

          <Button color="green" className="submit-quote-button" type="submit" onClick={this.onSubmit}>
            Save
          </Button>
        </Form>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  // get id from parameter
  const { id } = ownProps.routeParams;
  const quote = denormalizeQuote(state, id);

  if (!quote) {
    return {};
  }

  return {
    quote
  };
}
export default connect(mapStateToProps)(withRouter(AddQuoteApprovers));
