import { createAPIAction } from "redux-actions-api-addon";

export const getDashboardData = createAPIAction(
  "DASHBOARD",
  "GET",
  "/dashboard",
  null,
  () => ({
    authenticated: true
  })
);
