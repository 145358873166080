import PropTypes from "prop-types";
import React from "react";
import get from "lodash/get";
import moment from "moment";
import numeral from "numeral";
import isEmpty from "lodash/isEmpty";
import Logo from "../OCLM-Logo-2023.jpg";
import { formatQuoteNumber, getQuoteTotal } from "../../../utils/quotes";
import { nl2br } from "../../../utils/string";
// import "./Default.css";

export default function OceanHills({ id, manager = {}, created_at, name, description, notes, quote_items = [], user }) {
  return (
    <div>
      <div className="view-quote-header">
        <img width="350" src={Logo} alt="presentation" />
      </div>

      <h1>Quote # {formatQuoteNumber(id, created_at)}</h1>

      <table className="tbl-quote-header">
        <tbody>
          <tr>
            <td>
              {!Boolean(isEmpty(manager) || isEmpty(manager.management)) && (
                <p>
                  <strong>Customer Information:</strong>
                  <br />
                  {manager.name}
                  <br />
                  {manager.email}
                  <br />
                  {manager.phone}
                </p>
              )}
              {Boolean(isEmpty(manager) || isEmpty(manager.management)) && <p>Customer may not exist or is deleted</p>}

              <p>
                <strong>Management Information:</strong>
                <br />
                {get(manager, "management.company_name")}
                <br />
                {get(manager, "management.street_address")}
                <br />
                {Boolean(manager.management) && (
                  <span>
                    {get(manager, "management.city")}, {get(manager, "management.zip")}
                  </span>
                )}
              </p>
            </td>
            <td className="tbl-job-details">
              <p>
                <b>Date: </b>
                {moment(created_at).format("MM/DD/YYYY")}
                <br />
                <b>Submitted By: </b>
                {user.fullName}
                <br />
                <b>Email: </b>
                {user.email}
                <br />
                <b>Job Name: </b>
                {name}
                <br />
                <b>Job Description: </b>
                {description}
              </p>
            </td>
          </tr>
        </tbody>
      </table>

      <table className="tbl-quote">
        <thead>
          <tr>
            <th className="col-line-no">Line No.</th>
            <th>Location</th>
            <th>Description</th>
            <th>Qty</th>
            <th>Type</th>
            <th>Price</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {quote_items.map((obj, index) => {
            return (
              <tr key={obj.id}>
                <td className="col-line-no">{index + 1}</td>
                <td>
                  <span className="mobile-only">Location: </span>
                  {obj.location}
                </td>
                <td>
                  <span className="mobile-only">Description: </span>
                  {obj.description}
                </td>
                <td>
                  <span className="mobile-only">Quantity: </span>
                  {numeral(obj.quantity).format("0,0.00")}
                </td>
                <td>
                  <span className="mobile-only">Unit Type: </span>
                  {obj.type}
                </td>
                <td>
                  <span className="mobile-only">Price: </span>
                  {numeral(obj.price).format("$0,0.00")}
                </td>
                <td>
                  <span className="mobile-only">Total: </span>
                  {numeral(obj.price * obj.quantity).format("$0,0.00")}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="quote-total">
        <b>Total: {numeral(getQuoteTotal(quote_items)).format("$0,0.00")}</b>
      </div>

      <div className="quotes-notes-container">
        <label>Notes</label>
        <div className="quotes-notes-text">{nl2br(notes)}</div>
      </div>
    </div>
  );
}

OceanHills.defaultProps = {
  customers: {},
  user: {},
};

OceanHills.propTypes = {
  user: PropTypes.object,
};
