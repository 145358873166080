import React, { Component } from "react";
import shortid from "shortid";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push as rrPush } from "react-router-redux";
import * as actions from "../../actions/quotes";
import { getRegionalManagers } from "../../actions/users";
import get from "lodash/get";

import QuoteForm from "./QuoteForm";
import { sortQuoteItems } from "../../utils/quotes";

import { uploadAssets, removeAssets, updateAssets } from "../../utils/assets";

class CreateQuote extends Component {
  state = {
    addNewCustomer: false,
  };

  componentDidMount() {
    const { dispatch, getQuoteById, params } = this.props;
    const { id } = params;
    // only fetch if we are editing
    if (id) {
      getQuoteById(id);
    }
    dispatch(getRegionalManagers());
  }

  onSubmit = (values) => {
    const { createQuote, editQuote, route, push } = this.props;
    const { deletePictures, images = [] } = values;
    const newPictures = images.filter((obj) => !obj.id);
    const existingPictures = images.filter((obj) => obj.id);

    if (route.isEditing) {
      const { id } = this.props.params;
      const formattedValues = sortQuoteItems(values);
      editQuote(id, formattedValues)
        .then(() => removeAssets(deletePictures))
        .then(() => updateAssets(existingPictures))
        .then(() => uploadAssets(id, newPictures))
        .then((resp) => {
          if (deletePictures.length) {
            window.location.href = `/dashboard/quotes/view/${id}`;
          } else {
            push(`/dashboard/quotes/view/${id}`);
          }
        });
    } else {
      createQuote(values)
        .then(({ id: quoteId }) => {
          if (newPictures.length) {
            return uploadAssets(quoteId, newPictures).then(() => quoteId);
          }
          return quoteId;
        })
        .then((id) => {
          push(`/dashboard/quotes/view/${id}`);
        });
    }
  };

  render() {
    const {
      approval_id,
      approval_user_id,
      portal_manager_id,
      name,
      images,
      description,
      notes,
      quoteItems,
      route,
      customers,
      template_type,
    } = this.props;

    return (
      <div>
        <h1>{route.isEditing ? "Edit Quote" : "Create a Quote"}</h1>

        <QuoteForm
          submitLabel={route.isEditing ? "Update Quote" : "Save Quote"}
          isEditing={route.isEditing}
          customer={customers}
          portal_manager_id={portal_manager_id}
          values={{
            images,
            approval_id,
            approval_user_id,
            template_type,
            portal_manager_id,
            name,
            description,
            notes,
            deletePictures: [],
            quotes: quoteItems,
          }}
          onSubmit={this.onSubmit}
          onValidationFail={(values) => {
            console.log("failed validation", values);
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  // get id from parameter
  const { id } = ownProps.params;
  const { quotes, assets: assetEntities, quote_items: quoteItemEntities } = state.entities;

  const quote = quotes[id];

  const {
    assets,
    approval_user_id,
    approval_id,
    name,
    customers,
    portal_manager_id,
    description,
    notes,
    quote_items,
    template_type,
  } = quote || {};

  const images = (assets || []).map((id) => assetEntities[id]);
  const quoteItems = (quote_items || []).map((id) => quoteItemEntities[id]);

  return {
    images,
    approval_user_id,
    approval_id,
    customers,
    portal_manager_id,
    name,
    description,
    notes,
    quoteItems: quoteItems.length
      ? quoteItems
      : [
          {
            id: shortid.generate(),
            isNew: true,
            description: "Removal Fees",
          },
        ],
    template_type,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    push(loc) {
      return dispatch(rrPush(loc));
    },
    ...bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateQuote);
