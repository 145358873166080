import React from "react";
import moment from "moment";
import { Link } from "react-router";
import { connect } from "react-redux";
import { Button, Breadcrumb, Dropdown, Divider, Grid } from "semantic-ui-react";
import axios from "axios";
import { API_URL } from "../../constants";
import { getAccessToken } from "../../utils/token";

const statusOptions = [
  { key: "sendToClient", text: "Sent to Client", value: 1 },
  { key: "approved", text: "Approved", value: 2 },
  { key: "denied", text: "Denied", value: 3 },
  { key: "needsManagerApproval", text: "Needs Manager Approval", value: 4 },
  { key: "managerApproved", text: "Manager Approved", value: 5 },
  { key: "completed", text: "Completed", value: 6 },
];

const dateOptions = [
  {
    key: "NO_SELECTION",
    text: "None",
    value: {
      created_after: null,
      created_before: null,
    },
  },
  {
    key: "today",
    text: "Today",
    value: {
      created_after: moment().format("YYYY-MM-DDT00:00:00"),
    },
  },

  {
    key: "thisMonth",
    text: "This Month",
    value: {
      created_after: moment().format("YYYY-MM-DDT00:00:00"),
    },
  },

  {
    key: "thisYear",
    text: "This Year",
    value: {
      created_after: moment().month(0).date(1).format("YYYY-MM-DDT00:00:00"),
    },
  },
  {
    key: "lastYear",
    text: "Last Year",
    value: {
      created_after: moment().subtract(1, "year").month(0).date(1).format("YYYY-MM-DDT00:00:00"),
      created_before: moment().endOf("year").subtract(1, "year").format("YYYY-MM-DDT00:00:00"),
    },
  },
];

class Export extends React.Component {
  state = {
    exportData: {},
    isDownloading: false,
  };
  componentDidMount() {
    // const { dispatch } = this.props;
  }

  handleDateChange = (e, data) => {
    this.setState({
      exportData: {
        ...this.state.exportData,
        ...data.value,
      },
    });
  };

  handleStatusChange = (e, data) => {
    this.setState({
      exportData: {
        ...this.state.exportData,
        approval_id: data.value,
      },
    });
  };

  handleCSVDownload = () => {
    const { user } = this.props;
    const { exportData } = this.state;

    const payload = {
      queryOptions: exportData,
    };

    this.setState({
      isDownloading: true,
    });
    axios({
      url: `${API_URL}/api/download/csv`,
      method: "POST",
      data: payload,
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
      responseType: "blob", // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Export.csv");
      document.body.appendChild(link);
      link.click();

      this.setState({
        isDownloading: false,
      });
    });
  };

  render() {
    return (
      <div className="section-wrapper">
        <div className="quote-top-section">
          <Breadcrumb className="quote-breadcrumbs">
            <Breadcrumb.Section link>Home</Breadcrumb.Section>
            <Breadcrumb.Divider icon="right angle" />
            <Breadcrumb.Section link>
              <Link to="/dashboard/quotes">Quotes</Link>
            </Breadcrumb.Section>
          </Breadcrumb>
        </div>

        <h1>Export to CSV</h1>

        <Divider />

        <Grid columns={3} style={{ padding: "4rem 1rem" }}>
          <Grid.Row>
            <Grid.Column>
              <p>
                Date Range
                <br />
                <span className="help-text">If no date range is selected, Export will include ALL dates</span>
              </p>
            </Grid.Column>
            <Grid.Column>
              <Dropdown
                onChange={this.handleDateChange}
                placeholder="Date Range"
                fluid
                selection
                options={dateOptions}
              />
            </Grid.Column>
            <Grid.Column></Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <p>
                Quote Status
                <br /> <span className="help-text">If no status is selected, Export will include ALL statuses</span>
              </p>
            </Grid.Column>
            <Grid.Column>
              <Dropdown
                onChange={this.handleStatusChange}
                placeholder="Quote Status"
                fluid
                multiple
                selection
                options={statusOptions}
              />
            </Grid.Column>
            <Grid.Column></Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              {!this.state.isDownloading && (
                <Button color="green" className="btn--create" onClick={this.handleCSVDownload}>
                  Download CSV
                </Button>
              )}
              {this.state.isDownloading && (
                <Button color="gray" disabled>
                  ...Downloading
                </Button>
              )}
            </Grid.Column>
            <Grid.Column></Grid.Column>
            <Grid.Column></Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state;
  return {
    user,
  };
}

export default connect(mapStateToProps)(Export);
