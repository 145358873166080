import React from "react";
import { APPROVED, IN_PROGRESS_APPROVED } from "../../constants";
import { getApprovalStatus } from "../../utils/quotes";

export default function QuoteStatus({ quote = {}, approval_user }) {
  const { approval_id, approval_user_id, approved_by_manager, manager_approved_on } = quote;
  const approvalStatus = getApprovalStatus(approval_id, approval_user_id, manager_approved_on);
  return (
    <p>
      {Boolean(approvalStatus.key === APPROVED && approved_by_manager) && (
        <span className={`btn ${approvalStatus.className}`}>
          {approvalStatus.label} by {approved_by_manager.name} ({approved_by_manager.management.company_name})
        </span>
      )}
      {Boolean(approvalStatus.key === APPROVED) && !approved_by_manager && (
        <span className={`btn ${approvalStatus.className}`}>{approvalStatus.label}</span>
      )}

      {!Boolean(approvalStatus.key === APPROVED) && (
        <span className={`btn ${approvalStatus.className}`}>
          {approvalStatus.label}
          {approvalStatus.key === IN_PROGRESS_APPROVED && approval_user && (
            <span>&nbsp;({approval_user.fullName})</span>
          )}
        </span>
      )}
    </p>
  );
}
