import React from "react";
import axios from "axios";
import { FormInput } from "react-form";
import AsyncSelect from "react-select/lib/Async";

import { API_URL } from "../../constants";

function getValueByType(type = "manager", data) {
  switch (type) {
    case "management":
      return {
        value: data.value,
        label: `${data.company_name} (${data.street_address}, ${data.city}, ${data.zip})`
      };

    default:
      return {
        value: data.value,
        label: `${data.name} (${data.management.company_name})`
      };
  }
}

export default class CustomerSearch extends React.Component {
  state = {
    items: [],
    selected: null
  };
  getData = input => {
    const { type, searchField, value } = this.props;

    if (input) {
      return axios.get(`${API_URL}/api/search?q=${input}&type=${type}&field=${searchField}&limit=1000`).then(response =>
        response.data.map(resp => {
          return getValueByType(type, resp);
        })
      );
    } else {
      if (value) {
        axios.get(`${API_URL}/api/search?q=${value}&type=${type}&field=id&limit=1000`).then(resp => {
          if (resp && resp.data && resp.data.length) {
            const item = resp.data[0];
            this.setState({
              selected: getValueByType(type, item)
            });
          }
        });
      }
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.getData();
    }
  }

  render() {
    const { field, className, value, ...rest } = this.props;

    const { selected } = this.state;

    return (
      <FormInput field={field} className={className} value={value}>
        {({ setValue, getValue, setTouched }) => {
          // console.log("getValue", getValue());
          // console.log("this.props.value", this.props.value);
          return (
            <AsyncSelect
              {...rest}
              value={selected}
              defaultOptions
              loadOptions={this.getData}
              onChange={val => {
                // console.log("setting valeu", val.value);
                if (val) {
                  this.setState({
                    selected: val,
                    hasChanged: true
                  });
                  setValue(val.value);
                }
              }}
            />
          );
        }}
      </FormInput>
    );
  }
}

CustomerSearch.defaultProps = {
  type: "manager",
  searchField: "name"
};
