import React, { Component } from "react";
import { Link } from "react-router";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { bindActionCreators } from "redux";
import { Button, Icon, Dimmer, Loader } from "semantic-ui-react";
import pickBy from "lodash/pickBy";
import get from "lodash/get";

import QuoteFilters from "../../components/QuoteFilters";
import List from "./List";

import { FILTER_RESET } from "../../constants";
import * as actions from "../../actions/quotes";
import { denormalizeQuotes } from "../../utils/denormalizers";

const toggleFilters = (state) => ({
  filtersOpen: !state.filtersOpen,
});

class Quotes extends Component {
  state = {
    filtersOpen: false,
  };

  componentDidMount() {
    const pageNum = get(this.props, "location.query.p", 1);
    // const status = get(this.props, "location.query.status", 1);

    const { status, range, oclmuser, manager } = get(this.props, "location.query", {});

    if (status || range || oclmuser || manager) {
      this.setState({ filtersOpen: true });
    }

    const { getQuotes } = this.props;
    Promise.all([
      getQuotes({
        p: pageNum,
        status,
        oclmuser,
        manager,
        range,
      }),
    ]);
  }

  componentWillReceiveProps(nextProps) {
    const { query } = this.props.location;
    const { query: nextQuery } = nextProps.location;

    const {
      dir: nextDir,
      status: nextStatus,
      range: nextRange,
      oclmuser: nextOclmuser,
      manager: nextManager,
      orderBy: nextOrderBy,
      orderDir: nextOrderDir,
    } = nextProps.location.query;
    let { p: nextPageNum } = nextProps.location.query;

    // if (pageNum !== nextPageNum || orderBy !== nextOrderBy || dir !== nextDir) {
    if (query !== nextQuery) {
      this.props.getQuotes({
        p: nextPageNum,
        dir: nextDir,
        status: nextStatus,
        range: nextRange,
        oclmuser: nextOclmuser,
        manager: nextManager,
        orderBy: nextOrderBy,
        orderDir: nextOrderDir,
      });
    }
  }

  onFiltersOpen = () => this.setState(toggleFilters);

  onFiltersChange = (e, data) => {
    const { dispatch, location } = this.props;

    let filters;
    if (Array.isArray(data)) {
      filters = data.reduce((prev, cur) => {
        return {
          ...prev,
          [cur.name]: cur.value,
        };
      }, {});
    } else {
      filters = {
        [data.name]: data.value,
      };
    }

    // handle ability to RESET
    dispatch(
      push({
        ...location,
        query: pickBy(
          {
            ...location.query,
            ...filters,
            p: 1,
          },
          (val) => val !== FILTER_RESET
        ),
      })
    );
  };

  onFiltersReset = () => {
    const { dispatch, location } = this.props;
    dispatch(
      push({
        ...location,
        query: {},
      })
    );
  };

  render() {
    const { filtersOpen } = this.state;
    const { quotes, location, pagination, oclmusers } = this.props;
    return (
      <div className="section-wrapper">
        <h1>Quotes</h1>

        <div className="actions-top-panel">
          <div className="left-actions">
            <Link className="item " to="/dashboard/quotes/create">
              <Button color="red" className="btn--create">
                <Icon name="plus circle" />
                Create a Quote
              </Button>
            </Link>
            <Link className="item" to="/dashboard/main">
              <Button color="blue" className="btn--create">
                Dashboard
              </Button>
            </Link>
            <Link className="item" to="/dashboard/export">
              <Button color="green" className="btn--create">
                Export to CSV
              </Button>
            </Link>
          </div>

          <Button className="btn--create" onClick={this.onFiltersOpen}>
            {Boolean(filtersOpen) && (
              <span>
                <Icon name="close" />
                Close Filters
              </span>
            )}
            {!Boolean(filtersOpen) && (
              <span>
                <Icon name="filter" />
                Open Filters
              </span>
            )}
          </Button>
        </div>

        <QuoteFilters
          status={get(location, "query.status")}
          range={get(location, "query.range")}
          oclmuser={get(location, "query.oclmuser")}
          manager={get(location, "query.manager")}
          location={location}
          open={filtersOpen}
          onChange={this.onFiltersChange}
          onReset={this.onFiltersReset}
        />

        <section className="section section--quotes">
          <div className="content-panel">
            <List
              quotes={quotes}
              getQuotes={this.props.getQuotes}
              deleteQuote={this.props.deleteQuote}
              pagination={pagination}
              pageNum={get(this.props, "location.query.p", 1)}
              location={this.props.location}
              onFiltersChange={this.onFiltersChange}
              oclmusers={oclmusers}
            />
          </div>
        </section>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { quoteIds, pagination } = state.quotes;
  const { oclmusers } = state.users;

  const quotes = denormalizeQuotes(state, quoteIds);

  return {
    quotes,
    oclmusers,
    pagination,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Quotes);
