import React from "react";
import { Link } from "react-router";

export default function NavigationMobile({ user, showMobileMenu }) {
  return (
    <div className={`mobile-menu mobile-menu-${showMobileMenu}`}>
      <ul>
        {user.user_read && (
          <li>
            <a href={`${process.env.REACT_APP_CONTRACTS_APP_URL}/#/user`}>
              Users
            </a>
          </li>
        )}
        {user.pricing_group_read && (
          <li>
            <a
              href={`${process.env.REACT_APP_CONTRACTS_APP_URL}/#/pricinggroup`}
            >
              Pricing Groups
            </a>
          </li>
        )}
        {user.labor_rate_read && (
          <li>
            <a href={`${process.env.REACT_APP_CONTRACTS_APP_URL}/#/laborrate`}>
              Labor Rates
            </a>
          </li>
        )}
        {user.management_read && (
          <li>
            <a href={`${process.env.REACT_APP_CONTRACTS_APP_URL}/#/management`}>
              Management Info
            </a>
          </li>
        )}
        {user.manager_read && (
          <li>
            <a href={`${process.env.REACT_APP_CONTRACTS_APP_URL}/#/manager`}>
              Manager
            </a>
          </li>
        )}

        {user.quote_read && (
          <li>
            <Link to="/dashboard/quotes">Quotes</Link>
          </li>
        )}

        {user.job_read && (
          <li>
            <a href={`${process.env.REACT_APP_CONTRACTS_APP_URL}/#/jobsetup`}>
              Jobs
            </a>
          </li>
        )}
        {user.contract_template_read && (
          <li>
            <a
              href={`${
                process.env.REACT_APP_CONTRACTS_APP_URL
              }/#/contracttemplate`}
            >
              Contract Templates
            </a>
          </li>
        )}
        {user.contract_read && (
          <li>
            <a href={`${process.env.REACT_APP_CONTRACTS_APP_URL}/#/contract`}>
              Contract
            </a>
          </li>
        )}
        {user.raise_request_template_read && (
          <li>
            <a
              href={`${
                process.env.REACT_APP_CONTRACTS_APP_URL
              }/#/raiserequesttemplate`}
            >
              Raise Request Template
            </a>
          </li>
        )}
        {user.raise_request_read && (
          <li>
            <a
              href={`${process.env.REACT_APP_CONTRACTS_APP_URL}/#/raiserequest`}
            >
              Raise Request
            </a>
          </li>
        )}

        <li>
          <a href="https://goo.gl/forms/38MAyH1AITTVLLNs1" target="_blank">
            Report a Bug
          </a>
        </li>

        <li>
          <a
            href={`${process.env.REACT_APP_CONTRACTS_APP_URL}/#/passwordreset`}
          >
            Account
          </a>
        </li>

        <li>
          <a href={`${process.env.REACT_APP_CONTRACTS_APP_URL}/#/logout`}>
            Logout
          </a>
        </li>
      </ul>
    </div>
  );
}
