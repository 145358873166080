
import { push } from 'react-router-redux';
import { createAction } from 'redux-actions';
import { createAPIAction } from 'redux-actions-api-addon';
import { Schemas } from '../schemas';

export const getPurchaseOrders = createAPIAction(
  'PURCHASE_ORDERS',
  'GET',
  '/purchase_orders',
  null,
  () => ({
    authenticated: true,
    schema: Schemas.PURCHASE_ORDERS,
  })
);

export const getPurchaseOrder = createAPIAction(
  'PURCHASEORDERBYID',
  'GET',
  id => `/purchase_orders/${id}`,
  null,
  () => ({
    authenticated: true,
    schema: Schemas.PURCHASE_ORDER,
  })
);

export const deletePurchaseOrder = createAPIAction(
  'PURCHASE_ORDERS',
  'DELETE',
  id => `/purchase_orders/${id}`,
  null,
  () => ({
    authenticated: true,
  })
);
