import React from "react";
import { Route, IndexRoute } from "react-router";
import App from "./containers/App";
import LoginPage from "./containers/LoginPage";
import Register from "./containers/Register";
import AdminPage from "./containers/Admin";

import Quotes from "./containers/Quotes";
import CreateQuote from "./containers/Quotes/CreateQuote";
import ViewQuote from "./containers/Quotes/ViewQuote";
import EmailQuote from "./containers/EmailQuote";
import SubmitQuote from "./containers/Quotes/SubmitQuote";
import AddQuoteApprovers from "./containers/AddQuoteApprovers";

import Dashboard from "./containers/Dashboard/Dashboard";

// import EditCustomer from "./containers/Customers/EditCustomer";
// import Customers from "./containers/Customers/ViewCustomers";

import Uploader from "./containers/Uploader/UploadDemo";

import PurchaseOrders from "./containers/PurchaseOrders";
import ViewPurchaseOrder from "./containers/PurchaseOrders/ViewPurchaseOrder";

import SearchPage from "./containers/Search/SearchPage";

import Export from "./containers/Export/Export";

// import PortalPage from "./containers/Portal";

import * as onEnter from "./routes/functions";

// Empty holding component (used for window.location.href redirects)
const Empty = () => <div />;

export default (
  <Route path="/" component={App}>
    <Route path="/redirect" component={Empty} />
    <IndexRoute onEnter={onEnter.loginOrDashboard} />

    <Route path="/login" component={LoginPage} onEnter={onEnter.checkLogin} />
    <Route path="/register" component={Register} onEnter={onEnter.checkLogin} />

    <Route path="/logout" onEnter={onEnter.doLogout} />

    <Route path="/dashboard" component={AdminPage} onEnter={onEnter.checkAuth}>
      <IndexRoute component={Quotes} />
      <Route path="main" component={Dashboard} />
      <Route path="quotes" component={Quotes} />
      <Route path="quotes/create" component={CreateQuote} />
      <Route path="quotes/view/:id" component={ViewQuote} />
      <Route path="quotes/edit/:id" isEditing component={CreateQuote} />
      <Route path="quotes/submit/:id" component={SubmitQuote} />
      <Route path="quotes/email/:id" component={EmailQuote} />
      <Route path="quotes/add-approvers/:id" isEditing component={AddQuoteApprovers} />

      <Route path="purchase-orders" component={PurchaseOrders} />
      <Route path="purchase-orders/view/:id" component={ViewPurchaseOrder} />

      <Route path="export" component={Export} />

      <Route path="uploader" component={Uploader} />

      <Route path="search" component={SearchPage} />
    </Route>
  </Route>
);
