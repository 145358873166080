import axios from "axios";
import cookie from "react-cookie";

export const uploadAssets = (id, images) => {
  const requests = images.reduce((prev, item, idx) => {
    const data = new FormData();
    data.append(`image`, item);
    data.append(`quote_id`, id);
    data.append(`caption`, item.caption || "");
    return [
      ...prev,

      axios.post(`${process.env.REACT_APP_API_URL}/api/upload`, data, {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${cookie.load("token")}`,
        },
      }),
    ];
  }, []);

  return Promise.all(requests).catch((err) => console.error("Error uploading images"));
};

export const removeAssets = (images) => {
  const requests = images.reduce((prev, item, idx) => {
    return [
      ...prev,
      axios.delete(`${process.env.REACT_APP_API_URL}/api/assets/${item.id}`, {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${cookie.load("token")}`,
        },
      }),
    ];
  }, []);
  return Promise.all(requests);
};

export const updateAssets = (images = []) => {
  console.log("updateAssets", images);
  const requests = images.reduce((prev, item, idx) => {
    return [
      ...prev,

      axios.put(`${process.env.REACT_APP_API_URL}/api/assets/${item.id}`, item, {
        headers: {
          Authorization: `Bearer ${cookie.load("token")}`,
        },
      }),
    ];
  }, []);

  return Promise.all(requests).catch((err) => console.error("Error uploading images"));
};
